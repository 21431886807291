import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import {
  availableResourceAutoAssign,
  autoAssignCreateRequest,
  clearAutoAssignResources,
  fetchContactPersons,
  fetchServices,
  autoAssignOpeningHours,
} from '../../actions';
import {
  Translate,
  TFVButton,
  Modal,
  DropdownList,
  TFVTimePickerV2,
  TextField,
} from '../../../Share/components';
import {
  isIE,
  generateUniqueId,
  createDefaultContactPerson,
  removeContextualClassForBooknow,
} from '../../../Share/utils';
import {
  currentDate,
  durationHourAndMinutes,
  getNearest5MinutesInterval,
  getXTimefromSpecificDate,
} from '../../../Share/utils/dateUtil';
import CancelModal from './CancelModal';
import AvailableInterpreters from './AvailableInterpreters';
import { ContactPersonEdit } from '..';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import OrderComplete from './OrderComplete';
import './style.scss';
import history from '../../../Share/utils/history';
import { fetchAvailableSkills } from '../../actions/common';
import ConfirmUnavailableLanguage from './ConfirmUnavailableLanguage';
import PreviewEmgBooking from './PreviewEmgBooking';

const INITIAL_STATE = {
  DateNow: currentDate(),
  startTime: {},
  endDuration: {},
  language: {},
  contactPerson: {},
  contactPersonUponInterpretation: {},
  visibleCancelModal: false,
  visibleAvailabilityInterpretorModal: false,
  visibleCompletionModal: false,
  visibleRetryMessage: false,
  durations: [],
  duration: {},
  dateRange: {},
  moveForward: true,
  showLoadingOverlay: false,
  showContactPersonError: false,
  showContactPersonUponInterpertationError: false,
  bookDirectUnavailable: false,
  showContactPersonModal: false,
  showDurationError: false,
  defaultContactPerson: {},
  openDropdown: false,
  showConfirmUnavailableModal: false,
  isContactPersonDropdownExpanded: false,
  isLastContactPerson: false,
  isContactPersonFooterFocused: false,
  isContactOrderPersonDropdownExpanded: false,
  isLastContactOrderPerson: false,
  isContactOrderPersonFooterFocused: false,
  contactPersonUponInterpretationFocus: false,
  contactPersonFocus: false,
  genderValue: null,
  genderRequirement: false,
  isTelefontolkning: false,
  lmaNumber: null,
  showLMANumber: false,
};

const gender = [
  { value: '1', name: Translate({ content: 'general.male' }) },
  { value: '2', name: Translate({ content: 'general.female' }) },
];

class BookNowModal extends PureComponent {
  constructor(props) {
    super(props);
    this.originalTitle = document.title;
    const { validCustomerInvoices } = props;
    let validCustomerInvoice = { name: null, value: null };
    if (validCustomerInvoices.length === 1) {
      validCustomerInvoice = {
        name: validCustomerInvoices[0].name,
        value: validCustomerInvoices[0].value,
      };
    }
    this.state = {
      ...INITIAL_STATE,
      sessionIdentifier: uuidv4(),
      showPreview: false,
      validCustomerInvoice,
    };
  }

  componentDidMount() {
    document.title = 'Boka akut tolk - TFV Kundportal';
    window.addEventListener('load', this.handleLoad);
    const {
      contactPersons,
      onGetContactPersons,
      bookDirectOpeningHours,
      fetchSkills,
      userProfile,
      doAllowLMANumberOnConnectedContract,
    } = this.props;
    const { DoShowLMANumber } = userProfile;

    if (
      !isEmpty(bookDirectOpeningHours.AutoAssignOrderOpeningHours) &&
      bookDirectOpeningHours.AutoAssignOrderOpeningHours[0].IsActive === false
    ) {
      this.setState({
        bookDirectUnavailable: true,
      });
    }
    if (bookDirectOpeningHours.AutoAssignOrderOpeningHours === null) {
      this.setState({
        bookDirectUnavailable: true,
      });
    }
    if (isEmpty(contactPersons)) {
      onGetContactPersons();
    }
    if (!isEmpty(bookDirectOpeningHours.AutoAssignOrderOpeningHours)) {
      this.setState({
        dateRange: {
          startTime: getNearest5MinutesInterval().add(5, 'minutes'),
          endTime: getNearest5MinutesInterval()
            .add(35, 'minutes')
            .add(30, 'minutes'),
          durationDifference: 30,
          bookDirectCloseHour: moment(
            moment(
              bookDirectOpeningHours.AutoAssignOrderOpeningHours[0]
                .DatetimeOpenTo,
            ),
          ),
          bookDirectOpenHour: moment(
            moment(
              bookDirectOpeningHours.AutoAssignOrderOpeningHours[0]
                .DatetimeOpenFrom,
            ),
          ),
        },
        timeFromNowPossibleToBook:
          bookDirectOpeningHours?.NoOfMinutesFromNowPossibleToBook || 120,
        defaultContactPerson: createDefaultContactPerson(),
      });
    }
    if (this.props.isTelephoneTalking) {
      this.setState({ isTelefontolkning: true });
    }
    if (DoShowLMANumber && doAllowLMANumberOnConnectedContract) {
      this.setState({
        showLMANumber: true,
      });
    }
    fetchSkills();
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.title = this.originalTitle;
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = event => {
    const { bookDirectUnavailable, visibleCompletionModal } = this.state;
    const { onCloseClicked } = this.props;

    if (event.key === 'Escape') {
      if (bookDirectUnavailable || visibleCompletionModal) {
        onCloseClicked();
      } else {
        this.cancelModalHandler();
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      dateRange: { endTime, startTime, bookDirectCloseHour },
      contactPerson,
      contactPersonUponInterpretation,
      language,
      visibleRetryMessage,
      sessionIdentifier,
      isLastContactPerson,
      isLastContactOrderPerson,
      isContactPersonDropdownExpanded,
      isContactOrderPersonDropdownExpanded,
      genderValue,
      genderRequirement,
      validCustomerInvoice,
      lmaNumber,
    } = this.state;
    const {
      createAutoAssignOrder,
      userProfile,
      bookDirectServiceID,
      fetchResourcesAutoAssign,
      returnedOrder,
      assignOrderError,
      bookDirectOpeningHours,
      ContactPersonUponInterpretationRequiresEmail,
      ContactPersonOrdererRequiresEmail,
      doAllowLMANumberOnConnectedContract,
    } = this.props;
    const { DoShowLMANumber } = userProfile;
    if (
      DoShowLMANumber !== prevProps.userProfile.DoShowLMANumber ||
      doAllowLMANumberOnConnectedContract !==
        prevProps.doAllowLMANumberOnConnectedContract
    ) {
      if (DoShowLMANumber && doAllowLMANumberOnConnectedContract) {
        this.setState({
          showLMANumber: true,
        });
      } else {
        this.setState({
          showLMANumber: false,
          lmaNumber: null,
        });
      }
    }
    if (
      bookDirectOpeningHours !== prevProps.bookDirectOpeningHours &&
      !isEmpty(bookDirectOpeningHours.AutoAssignOrderOpeningHours)
    ) {
      this.setState({
        dateRange: {
          startTime: getNearest5MinutesInterval().add(5, 'minutes'),
          endTime: getNearest5MinutesInterval()
            .add(35, 'minutes')
            .add(30, 'minutes'),
          durationDifference: 30,
          bookDirectCloseHour: moment(
            moment(
              bookDirectOpeningHours.AutoAssignOrderOpeningHours[0]
                .DatetimeOpenTo,
            ),
          ),
          bookDirectOpenHour: moment(
            moment(
              bookDirectOpeningHours.AutoAssignOrderOpeningHours[0]
                .DatetimeOpenFrom,
            ),
          ),
        },
        defaultContactPerson: createDefaultContactPerson(),
        timeFromNowPossibleToBook:
          bookDirectOpeningHours?.NoOfMinutesFromNowPossibleToBook || 120,
        defaultContactPerson: createDefaultContactPerson(),
      });
    }
    if (
      contactPerson !== prevState.contactPerson &&
      contactPerson.Email === null &&
      ContactPersonOrdererRequiresEmail === true
    ) {
      this.setState({ showContactPersonError: true, moveForward: false });
    }

    if (bookDirectCloseHour !== prevState.dateRange.bookDirectCloseHour) {
      this.generateDurationList();
    }

    if (
      contactPerson !== prevState.contactPerson &&
      contactPerson.Email !== null
    ) {
      this.setState({ showContactPersonError: false, moveForward: true });
    }
    if (
      contactPersonUponInterpretation !==
        prevState.contactPersonUponInterpretation &&
      contactPersonUponInterpretation.Email === null &&
      ContactPersonUponInterpretationRequiresEmail
    ) {
      this.setState({
        showContactPersonUponInterpertationError: true,
        moveForward: false,
      });
    }
    if (
      contactPersonUponInterpretation !==
        prevState.contactPersonUponInterpretation &&
      contactPersonUponInterpretation.Email !== null
    ) {
      this.setState({
        showContactPersonUponInterpertationError: false,
        moveForward: true,
      });
    }
    if (
      this.props.returnedOrder !== prevProps.returnedOrder &&
      this.props.returnedOrder.IsSuccess === true
    ) {
      this.setState({
        visibleCompletionModal: !this.state.visibleCompletionModal,
        visibleAvailabilityInterpretorModal: false,
        visibleRetryMessage: false,
      });
    }
    if (
      this.props.availableResourcesAutoAssign !==
        prevProps.availableResourcesAutoAssign &&
      this.props.availableResourcesAutoAssign.Errors === null &&
      this.props.availableResourcesAutoAssign
        .CanCreateOrderWithOriginalTimeOrderFromTo === false
    ) {
      this.setState({
        visibleAvailabilityInterpretorModal: true,
      });
    }
    if (
      this.props.availableResourcesAutoAssign !==
        prevProps.availableResourcesAutoAssign &&
      this.props.availableResourcesAutoAssign.Errors === null &&
      this.props.availableResourcesAutoAssign
        .CanCreateOrderWithOriginalTimeOrderFromTo
    ) {
      try {
        let genderIdPreferred = null;
        let doRequireCorrectGender = null;
        if (genderValue) {
          if (genderValue.value === '1' || genderValue.value === '2') {
            genderIdPreferred = parseInt(genderValue.value, 10);
            doRequireCorrectGender = genderRequirement;
          } else {
            this.setState({
              genderRequirement: false,
            });
          }
        }
        const DoRequireCorrectGender = doRequireCorrectGender;
        const GenderIdPreferred = genderIdPreferred;

        createAutoAssignOrder(
          bookDirectServiceID,
          contactPerson,
          contactPersonUponInterpretation,
          language,
          {
            TimeFrom: startTime.format('YYYY-MM-DD HH:mm:ss'),
            TimeTo: endTime.format('YYYY-MM-DD HH:mm:ss'),
          },
          userProfile,
          sessionIdentifier,
          DoRequireCorrectGender,
          GenderIdPreferred,
          validCustomerInvoice.value,
          lmaNumber ? lmaNumber : null,
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (
      this.props.bookDirectServiceID !== prevProps.bookDirectServiceID &&
      this.props.bookDirectServiceID === ''
    ) {
      this.setState({ moveForward: false });
    }
    if (this.props.isTelephoneTalking !== prevProps.isTelephoneTalking) {
      if (this.props.isTelephoneTalking) {
        this.setState({ isTelefontolkning: true });
      } else {
        this.setState({ isTelefontolkning: false });
      }
    }
    if (
      assignOrderError !== prevProps.assignOrderError &&
      !isEmpty(assignOrderError) &&
      typeof assignOrderError === 'object' &&
      assignOrderError[0].ErrorCode === 1075
    ) {
      fetchResourcesAutoAssign(
        bookDirectServiceID,
        language.value,
        startTime.add(5, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        endTime.format('YYYY-MM-DD HH:mm:ss'),
      );
      this.setState({
        visibleRetryMessage: !visibleRetryMessage,
      });
    }

    if (
      isLastContactPerson === true &&
      isContactPersonDropdownExpanded === true
    ) {
      document.addEventListener('keydown', this.handleContactPersonKeyDown);
    }
    if (
      prevState.isContactPersonFooterFocused !==
        this.state.isContactPersonFooterFocused &&
      this.state.isContactPersonFooterFocused
    ) {
      document.addEventListener('keydown', this.handleContactPersonKeyDownEnd);
    }

    if (
      isLastContactOrderPerson === true &&
      isContactOrderPersonDropdownExpanded === true
    ) {
      document.addEventListener(
        'keydown',
        this.handleOrderContactPersonKeyDown,
      );
    }
    if (
      prevState.isContactOrderPersonFooterFocused !==
        this.state.isContactOrderPersonFooterFocused &&
      this.state.isContactOrderPersonFooterFocused
    ) {
      document.addEventListener(
        'keydown',
        this.handleOrderContactPersonKeyDownEnd,
      );
    }
  }

  handleContactPersonKeyDown = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Tab') {
      const parentSpan = document.querySelector('.k-animation-container');
      if (parentSpan) {
        const childContainer = parentSpan.querySelector(
          '.k-child-animation-container',
        );

        if (childContainer) {
          const footerElement = document.getElementById(
            'tv-dropdown__booknow__footer-conactperson__id',
          );
          if (footerElement) {
            const activeElement = document.activeElement;
            footerElement.focus();
            this.setState({
              isContactPersonFooterFocused: true,
            });
            document.removeEventListener(
              'keydown',
              this.handleContactPersonKeyDown,
            );
          }
        }
      } else {
        this.setState({
          isContactPersonFooterFocused: false,
        });
        document.removeEventListener(
          'keydown',
          this.handleContactPersonKeyDown,
        );
      }
    } else if (e.key === 'ArrowUp') {
      this.setState({
        isContactPersonFooterFocused: false,
      });
      document.removeEventListener('keydown', this.handleContactPersonKeyDown);
    }
  };

  handleContactPersonKeyDownEnd = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isContactPersonFooterFocused: false,
      isContactPersonDropdownExpanded: false,
    });
    document.removeEventListener('keydown', this.handleContactPersonKeyDownEnd);
  };

  handleOrderContactPersonKeyDown = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Tab') {
      const parentSpan = document.querySelector('.k-animation-container');
      if (parentSpan) {
        const childContainer = parentSpan.querySelector(
          '.k-child-animation-container',
        );

        if (childContainer) {
          const footerElement = document.getElementById(
            'tv-dropdown__footer-orderconactperson__id',
          );
          if (footerElement) {
            footerElement.focus();
            this.setState({
              isContactOrderPersonFooterFocused: true,
            });
            document.removeEventListener(
              'keydown',
              this.handleOrderContactPersonKeyDown,
            );
          }
        }
      } else {
        this.setState({
          isContactOrderPersonFooterFocused: false,
        });
        document.removeEventListener(
          'keydown',
          this.handleOrderContactPersonKeyDown,
        );
      }
    } else if (e.key === 'ArrowUp') {
      this.setState({
        isContactOrderPersonFooterFocused: false,
      });
      document.removeEventListener(
        'keydown',
        this.handleOrderContactPersonKeyDown,
      );
    }
  };

  handleOrderContactPersonKeyDownEnd = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isContactOrderPersonFooterFocused: false,
      isContactOrderPersonDropdownExpanded: false,
    });
    document.removeEventListener(
      'keydown',
      this.handleOrderContactPersonKeyDownEnd,
    );
  };

  getValueFromList = (filterText, list) => {
    if (filterText === null || filterText === '' || filterText === undefined) {
      return null;
    }
    const filteredItems = list.filter(x => x.value === filterText);
    return filteredItems ? filteredItems[0] : null;
  };

  generateDuration = (startTime, endTime) => {
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    // duration in hours
    const hours = parseInt(duration.asHours(), 10);

    // duration in minutes
    const minutes = parseInt(duration.asMinutes(), 10) % 60;

    return {
      name: `${hours === 0 ? '' : `${hours} h `}${
        minutes === 0 ? '' : `${minutes} min`
      }`,
      value: parseInt(duration.asMinutes(), 10),
    };
  };

  generateDurationList = () => {
    const {
      dateRange: { startTime, bookDirectCloseHour },
    } = this.state;
    const endTime = currentDate().add(24, 'hours');
    const tomorrow8Time = currentDate()
      .startOf('day')
      .add(1, 'days')
      .add(8, 'hours');

    let bookCloseTime;
    if (endTime < tomorrow8Time) {
      bookCloseTime = endTime;
    } else {
      bookCloseTime = tomorrow8Time.add(1, 'minutes');
    }

    let i = 0;
    let currentTime = moment(startTime);
    const durationList = [];
    const timeDifference = moment.duration(
      moment(bookCloseTime).diff(moment(currentTime)),
    );

    const diff = parseInt(timeDifference.asHours() * 4, 10);
    while (i < diff) {
      currentTime = currentTime.add(15, 'minute');

      durationList.push(
        this.generateDuration(moment(startTime), moment(currentTime)),
      );
      i += 1;
    }
    // durationList.unshift({ name: '15 min', value: 15 });
    this.setState({
      durations: durationList,
    });
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;
    const { executeGetServices, contactPersons } = this.props;
    const { dateRange, timeFromNowPossibleToBook } = this.state;
    if (name === 'duration') {
      const endTime = getXTimefromSpecificDate(
        dateRange.startTime,
        value.value,
        'minutes',
      );

      const duration = moment.duration(moment(endTime).diff(moment()));
      const hours = parseInt(duration.asHours(), 10);
      const timeFromNowPossibleToBookInHours = moment
        .duration(timeFromNowPossibleToBook, 'minutes')
        .asHours();
      if (hours >= timeFromNowPossibleToBookInHours) {
        this.setState({ moveForward: false, showDurationError: true });
      } else {
        this.setState({ moveForward: true, showDurationError: false });
      }

      dateRange.endTime = endTime;
      dateRange.durationDifference = value.value;
      this.setState({ dateRange });
    }

    if (name === 'language') {
      if (!this.state.openDropdown) {
        if (
          event.nativeEvent.key === 'ArrowDown' ||
          event.nativeEvent.key === 'ArrowUp'
        ) {
          if (!value.isAvailable) {
            this.setState({
              showConfirmUnavailableModal: true,
            });
          }
        }
      } else {
        const params = {
          language: value.value,
          startTime: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          endTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        };
        if (value.isAvailable) {
          executeGetServices(params);
        }
      }
    }

    if (name === 'contactPersonUponInterpretation') {
      if (
        contactPersons[contactPersons.length - 1].ContactPersonIdentifier ===
        value.ContactPersonIdentifier
      ) {
        this.setState({
          isLastContactPerson: true,
        });
      } else {
        this.setState({
          isLastContactPerson: false,
        });
      }
    }

    if (name === 'contactPerson') {
      if (
        contactPersons[contactPersons.length - 1].ContactPersonIdentifier ===
        value.ContactPersonIdentifier
      ) {
        this.setState({
          isLastContactOrderPerson: true,
        });
      } else {
        this.setState({
          isLastContactOrderPerson: false,
        });
      }
    }

    this.setState({ [name]: value });
  };

  handleClose = e => {
    const {
      target: { value },
    } = e;
    if (
      e.nativeEvent.key === 'Enter' ||
      e.nativeEvent.type === 'blur' ||
      e.nativeEvent.type === 'click'
    ) {
      if (!value.isAvailable) {
        this.setState({
          showConfirmUnavailableModal: true,
        });
      }
    }
    this.setState({ openDropdown: false });
  };

  handleOpen = e => {
    this.setState({ openDropdown: true });
  };

  cancelModalHandler = () => {
    const { visibleCancelModal } = this.state;
    this.setState({
      visibleCancelModal: !visibleCancelModal,
    });
  };

  goBackHandler = () => {
    const { clearAutoAssignResources } = this.props;
    clearAutoAssignResources();
    this.setState({
      showPreview: false,
      visibleCompletionModal: false,
      visibleCancelModal: false,
      visibleAvailabilityInterpretorModal: false,
    });
  };

  createNewBookingAfterSuccess = () => {
    const { getAutoAssignOpeningHours } = this.props;
    this.setState({
      ...INITIAL_STATE,
    });
    getAutoAssignOpeningHours({
      DateFrom: moment().format('YYYY-MM-DD'),
      DateTo: moment().format('YYYY-MM-DD'),
    });
  };

  redirectToCalendar = () => {
    history.push('/');
    this.props.onCloseClicked();
    this.setState({
      ...INITIAL_STATE,
    });
  };

  completionModalHandler = () => {
    this.setState({
      visibleCancelModal: false,
      visibleAvailabilityInterpretorModal: false,
      visibleCompletionModal: false,
      visibleRetryMessage: false,
      language: {},
      contactPerson: {},
      contactPersonUponInterpretation: {},
      duration: {},
      dateRange: {
        startTime: getNearest5MinutesInterval().add(5, 'minutes'),
      },
    });
  };
  onCancelModalCloseClicked = () => {
    this.setState({
      visibleCancelModal: false,
    });
  };

  showRetryMessageHandler = () => {
    const { visibleRetryMessage } = this.state;
    this.setState({
      visibleRetryMessage: !visibleRetryMessage,
    });
  };

  handleSessionChange = (e, key, rowNumber) => {
    const { dateRange: stateDateRange, timeFromNowPossibleToBook } = this.state;
    const dateRange = stateDateRange;
    dateRange[key] = e;
    if (key === 'startTime') {
      const { startTime, endTime } = dateRange;

      if (
        moment(
          getXTimefromSpecificDate(
            startTime,
            dateRange.durationDifference,
            'minutes',
          ),
        ) > moment(dateRange.bookDirectCloseHour)
      ) {
        this.setState({
          duration: {},
        });
      }

      dateRange.endTime = getXTimefromSpecificDate(
        startTime,
        dateRange.durationDifference,
        'minutes',
      );

      const duration = moment.duration(
        moment(dateRange.endTime).diff(moment()),
      );
      const hours = parseInt(duration.asHours(), 10);
      const timeFromNowPossibleToBookInHours = moment
        .duration(timeFromNowPossibleToBook, 'minutes')
        .asHours();
      if (hours >= timeFromNowPossibleToBookInHours) {
        this.setState({ moveForward: false, showDurationError: true });
      } else {
        this.setState({ moveForward: true, showDurationError: false });
      }
    }

    this.setState({
      dateRange,
    });
    this.generateDurationList();
  };

  handleSubmit = () => {
    let isValid = true;
    const { genderValue } = this.state;
    try {
      if (isValid) {
        if (genderValue) {
          if (genderValue.value !== '1' && genderValue.value !== '2') {
            this.setState({
              genderRequirement: false,
            });
          }
        }
        this.setState({
          showPreview: true,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleSubmitPreview = () => {
    const {
      dateRange: { endTime, startTime },
      language,
      sessionIdentifier,
      genderValue,
      genderRequirement,
    } = this.state;

    let genderIdPreferred = null;
    let doRequireCorrectGender = null;
    if (genderValue) {
      if (genderValue.value === '1' || genderValue.value === '2') {
        genderIdPreferred = parseInt(genderValue.value, 10);
        doRequireCorrectGender = genderRequirement;
      } else {
        this.setState({
          genderRequirement: false,
        });
      }
    }

    const DoRequireCorrectGender = doRequireCorrectGender;
    const GenderIdPreferred = genderIdPreferred;
    const { fetchResourcesAutoAssign, bookDirectServiceID } = this.props;
    try {
      fetchResourcesAutoAssign(
        bookDirectServiceID,
        language.value,
        startTime.format('YYYY-MM-DD HH:mm:ss'),
        endTime.format('YYYY-MM-DD HH:mm:ss'),
        sessionIdentifier,
        DoRequireCorrectGender,
        GenderIdPreferred,
      );
    } catch (e) {
      console.log(e);
    }
  };

  createBooking = slot => {
    const { TimeFrom, DatetimeFrom } = slot;

    const {
      dateRange: { durationDifference },
      contactPerson,
      contactPersonUponInterpretation,
      language,
      visibleRetryMessage,
      sessionIdentifier,
      genderValue,
      genderRequirement,
      validCustomerInvoice,
      lmaNumber,
    } = this.state;
    const {
      createAutoAssignOrder,
      userProfile,
      bookDirectServiceID,
      fetchResourcesAutoAssign,
    } = this.props;

    const timeFromSplitArray = TimeFrom.split(':');
    const timeTo = moment()
      .set({
        hour: timeFromSplitArray[0],
        minute: timeFromSplitArray[1],
      })
      .add(durationDifference, 'minutes');

    let genderIdPreferred = null;
    let doRequireCorrectGender = null;
    if (genderValue) {
      if (genderValue.value === '1' || genderValue.value === '2') {
        genderIdPreferred = parseInt(genderValue.value, 10);
        doRequireCorrectGender = genderRequirement;
      } else {
        this.setState({
          genderRequirement: false,
        });
      }
    }
    const DoRequireCorrectGender = doRequireCorrectGender;
    const GenderIdPreferred = genderIdPreferred;
    try {
      createAutoAssignOrder(
        bookDirectServiceID,
        contactPerson,
        contactPersonUponInterpretation,
        language,
        {
          TimeFrom: moment(DatetimeFrom).format('YYYY-MM-DD HH:mm:ss'),
          TimeTo: timeTo.format('YYYY-MM-DD HH:mm:ss'),
        },
        userProfile,
        sessionIdentifier,
        DoRequireCorrectGender,
        GenderIdPreferred,
        validCustomerInvoice.value,
        lmaNumber ? lmaNumber : null,
      );
    } catch (error) {
      console.log(error);
    }
  };

  handleContactPersonModal = (emailRequired, type = '', identifier) => {
    const { defaultContactPerson } = this.state;
    const { contactPersons } = this.props;
    let editableContactPerson = defaultContactPerson;
    if (identifier && identifier !== '') {
      let matchingContactPersonsList = contactPersons.filter(
        x => x.value === identifier,
      );
      matchingContactPersonsList =
        matchingContactPersonsList.length > 0
          ? matchingContactPersonsList[0]
          : {};

      editableContactPerson = {
        ...editableContactPerson,
        ...matchingContactPersonsList,
        editMode: true,
      };
    }
    this.setState({
      showContactPersonModal: true,
      defaultContactPerson: {
        ...editableContactPerson,
        identifier,
        emailRequired,
        type,
      },
    });
  };

  handleContactPersonModalClose = modalState => {
    const { defaultContactPerson } = this.state;
    if (
      defaultContactPerson.type === 'ContactPersonOrderer' &&
      modalState === 'success'
    ) {
      this.setState({
        showContactPersonError: false,
        moveForward: true,
      });
    }
    if (
      defaultContactPerson.type === 'ContactPersonUponInterpretation' &&
      modalState === 'success'
    ) {
      this.setState({
        showContactPersonUponInterpertationError: false,
        moveForward: true,
      });
    }
    this.setState({
      showContactPersonModal: false,
      defaultContactPerson: createDefaultContactPerson(),
    });
  };

  itemRender = (element, { dataItem }) => {
    if (!dataItem || dataItem.isAvailable) {
      return element;
    }

    const children = [
      <span
        key={dataItem.value}
        style={{
          color: '#ccc',
        }}
      >
        {dataItem.name}
      </span>,
    ];

    return React.cloneElement(
      element,
      {
        ...element.props,
        style: {
          ...element.props.style,
          cursor: 'default',
        },
      },
      children,
    );
  };

  closeUnavailableLanguageModal = () => {
    this.setState({
      showConfirmUnavailableModal: false,
      language: {},
    });
  };

  getEndTime = () => {
    const endTime = currentDate().add(24, 'hours');
    const tomorrow8Time = currentDate()
      .startOf('day')
      .add(1, 'days')
      .add(8, 'hours');
    if (endTime < tomorrow8Time) {
      return endTime.add(30, 'minutes');
    }
    return tomorrow8Time.add(30, 'minutes');
  };

  handleFormError = () => {};

  handleOpenContactPerson = () => {
    this.setState({
      isContactPersonDropdownExpanded: true,
      contactPersonUponInterpretationFocus: true,
    });
  };

  handleOpenOrderContactPerson = () => {
    this.setState({
      isContactOrderPersonDropdownExpanded: true,
      contactPersonFocus: true,
    });
  };

  handleCloseContactPerson = e => {
    const {
      target: { value },
    } = e;
    if (e.nativeEvent.type === 'click' || e.nativeEvent.type === 'blur') {
      this.setState({
        isContactPersonDropdownExpanded: false,
      });
    }
    if (e.nativeEvent.key === 'Enter') {
      this.setState({
        isContactPersonDropdownExpanded: false,
      });
    }
    if (e.nativeEvent.type === 'blur' && this.state.isLastContactPerson) {
      this.setState({
        isContactPersonDropdownExpanded: true,
      });
    }
    this.setState({
      contactPersonUponInterpretationFocus: false,
    });
  };

  handleCloseOrderContactPerson = e => {
    const {
      target: { value },
    } = e;
    if (e.nativeEvent.type === 'click' || e.nativeEvent.type === 'blur') {
      this.setState({
        isContactOrderPersonDropdownExpanded: false,
      });
    }
    if (e.nativeEvent.key === 'Enter') {
      this.setState({
        isContactOrderPersonDropdownExpanded: false,
      });
    }
    if (e.nativeEvent.type === 'blur' && this.state.isLastContactOrderPerson) {
      this.setState({
        isContactOrderPersonDropdownExpanded: true,
      });
    }
    this.setState({
      contactPersonFocus: false,
    });
  };

  handleRadioButtonChange = (e, controlName) => {
    const contollerValue = e.target.value;
    switch (controlName) {
      case 'genderRequirement':
        {
          let genderRequirement = false;
          if (contollerValue == 2) {
            genderRequirement = true;
          }
          this.setState({ ...this.state, genderRequirement });
        }
        break;
      default:
        break;
    }
  };

  render() {
    const {
      isVisible,
      onOverlayClick,
      onCloseClicked,
      languages,
      contactPersons,
      customerName,
      availableResourcesAutoAssign,
      returnedOrder,
      validCustomerInvoices,
    } = this.props;
    const {
      DateNow,
      language,
      contactPerson,
      contactPersonUponInterpretation,
      dateRange,
      visibleCancelModal,
      visibleAvailabilityInterpretorModal,
      visibleCompletionModal,
      visibleRetryMessage,
      durations,
      duration,
      moveForward,
      showContactPersonError,
      showContactPersonUponInterpertationError,
      bookDirectUnavailable,
      showContactPersonModal,
      defaultContactPerson,
      showDurationError,
      showConfirmUnavailableModal,
      timeFromNowPossibleToBook,
      contactPersonUponInterpretationFocus,
      contactPersonFocus,
      showPreview,
      genderValue,
      genderRequirement,
      validCustomerInvoice,
      isTelefontolkning,
      lmaNumber,
      showLMANumber,
    } = this.state;
    const rangeEndTime = currentDate()
      .startOf('day')
      .add(1, 'days')
      .add(8, 'hours')
      .add(30, 'minutes');
    return (
      <>
        {showContactPersonModal && (
          <ContactPersonEdit
            {...defaultContactPerson}
            showModal={showContactPersonModal}
            inOrderProcess
            onHandleModal={this.handleContactPersonModalClose}
          />
        )}
        <Modal
          className={`tv-booknow__modal tv-booknow_align-unset ${
            visibleCancelModal ? 'tv-booknow__modal-hidden' : ''
          }`}
          // onCloseClicked={
          //   bookDirectUnavailable || visibleCompletionModal
          //     ? onCloseClicked
          //     : this.cancelModalHandler
          // }
          onCloseClicked={() => {
            if (bookDirectUnavailable || visibleCompletionModal) {
              onCloseClicked();
              removeContextualClassForBooknow();
            } else {
              this.cancelModalHandler();
            }
          }}
          //  onOverlayClick={this.cancelModalHandler}
          isVisible={isVisible}
          headerClassName={`tv-workassignment__modal-header ${
            isIE ? 'ie-fix-width' : ''
          }`}
          headerText={
            showPreview
              ? Translate({
                  content: 'singleAssignment.assignmentDetail',
                })
              : !visibleCompletionModal
                ? Translate({
                    content: 'bookNow.bookAnInterpreterToday',
                  })
                : ''
          }
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
          contactPersonUponInterpretationFocus={
            contactPersonUponInterpretationFocus
          }
          contactPersonFocus={contactPersonFocus}
          paused
        >
          {!visibleAvailabilityInterpretorModal &&
            !visibleCompletionModal &&
            !bookDirectUnavailable &&
            !showPreview && (
              <div className="tv-booknow__container flex-column">
                <div className="tv-booknow__text">
                  Akut tolk avser telefontolk innevarande dag samt nästkommande
                  dag fram till kl. 08.00. Nu direkt eller inom ett par timmar.
                </div>
                <div className="tv-booknow__text mt-3">
                  Tolk inom 15 minuter innebär akuttaxa. Övrig tid ordinarie
                  telefontolkningstaxa.
                </div>
                <div className="tv-booknow__text mt-3">
                  Fyll i formuläret och tryck på boka nu för att direkt få
                  tillgång till en tolk.
                </div>
                <div className="tv-booknow__text mt-3 mb-4">
                  Tolkens telefonnummer kommer att visas på nästa sida. Skulle
                  ingen tolk finnas den önskade tiden kommer du få ett förslag
                  på andra närliggande tider då tolk finns tillgänglig.
                </div>
                {/* <div className="tv-booknow__text tv-booknow__text-date">
                  <b>
                    Tolkens telefonnummer kommer att visas på nästa sida. Skulle
                    ingen tolk finnas den önskade tiden kommer du få ett förslag
                    på andra närliggande tider.
                  </b>
                </div> */}
                {/* <div className="tv-booknow__text tv-booknow__text-date">
                  Bokningen gäller för telefontolkning idag{' '}
                  <b>{DateNow.format('ddd DD MMMM YYYY')}</b>{' '}
                  <b>
                    {moment(dateRange.bookDirectOpenHour).format('HH:mm')} -{' '}
                    {moment(dateRange.bookDirectCloseHour).format('HH:mm')}
                  </b>
                </div> */}

                <div className="tv-booknow__form quick-order-cont side">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={this.handleFormError}
                  >
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 tv-booknow_col">
                        <div className="form-group">
                          <label htmlFor="startTime">
                            {Translate({ content: 'bookNow.startTime' })}
                          </label>
                          <TFVTimePickerV2
                            // key={`${generateUniqueId()}-BookDireact-TFVTimePickerV2-start`}
                            defaultValue={dateRange.startTime}
                            handleValueChange={e =>
                              this.handleSessionChange(e, 'startTime')
                            }
                            // rangeEndTime={dateRange.bookDirectCloseHour}
                            rangeEndTime={this.getEndTime()}
                            className={
                              dateRange.startTime < moment()
                                ? 'tv-form__control--error--timePicker'
                                : ''
                            }
                            fromBookNowModal={true}
                            ariaLabel="Önskad starttid"
                            name="startTime"
                            id="startTime"
                          />
                          {dateRange.startTime < moment() && (
                            <div className="tv-col-12">
                              <span className="tv-form__error">
                                Starttiden har redan passerats. Välj en starttid
                                från nu eller senare.
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 tv-booknow_col">
                        <div className="form-group">
                          <label htmlFor="endDuration">
                            {Translate({ content: 'bookNow.executionTime' })}
                          </label>
                          <DropdownList
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="duration"
                            required
                            defaultItem={{ name: 'Välj längd', value: '' }}
                            value={this.getValueFromList(
                              duration.value,
                              durations,
                            )}
                            className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                            options={durations}
                            validators={['required']}
                            placeholder="Välj längd"
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content: 'bookNow.executionTime',
                                  }),
                                },
                              }),
                            ]}
                            ariaLabel={Translate({
                              content: 'bookNow.executionTime',
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 tv-booknow_col">
                        <div className="form-group">
                          <label htmlFor="language">
                            {Translate({ content: 'bookNow.language' })}
                          </label>
                          <DropdownList
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="language"
                            required
                            defaultItem={{ name: 'Välj språk', value: '' }}
                            value={this.getValueFromList(
                              language.value,
                              languages,
                            )}
                            className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                            options={languages}
                            validators={['required']}
                            placeholder="Välj språk"
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content: 'bookNow.language',
                                  }),
                                },
                              }),
                            ]}
                            itemRender={this.itemRender}
                            onOpen={this.handleOpen}
                            onClose={this.handleClose}
                            ariaLabel={Translate({
                              content: 'bookNow.language',
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-6 p-2">
                        <div className="form-group">
                          <label htmlFor="contactPersonUponInterpretation">
                            {Translate({
                              content: 'bookNow.contactPersonUponInterpretor',
                            })}
                          </label>
                          <DropdownList
                            opened={this.state.isContactPersonDropdownExpanded}
                            onClose={this.handleCloseContactPerson}
                            onOpen={this.handleOpenContactPerson}
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="contactPersonUponInterpretation"
                            required
                            value={this.getValueFromList(
                              contactPersonUponInterpretation.value,
                              contactPersons,
                            )}
                            defaultItem={{
                              name: 'Välj Tolkanvändare',
                              value: '',
                            }}
                            className={`ellipsis pl-3 py-1 tv-single-assign-form__control tv-single-assign-form__control-contact-person ${
                              showContactPersonUponInterpertationError
                                ? 'tv-form__control--error'
                                : ''
                            }`}
                            options={contactPersons}
                            validators={['required']}
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content:
                                      'bookNow.contactPersonUponInterpretor',
                                  }),
                                },
                              }),
                            ]}
                            footer={
                              <span
                                onClick={e => {
                                  e.stopPropagation();
                                  this.handleContactPersonModal(
                                    showContactPersonUponInterpertationError,
                                    'ContactPersonUponInterpretation',
                                  );
                                  this.setState({
                                    isContactPersonDropdownExpanded: false,
                                  });
                                }}
                                className="tv-dropdown__footer-conactperson-add tv-dropdown__footer-conactperson-add-contactPerson"
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.handleContactPersonModal(
                                      showContactPersonUponInterpertationError,
                                      'ContactPersonUponInterpretation',
                                    );
                                    this.setState({
                                      isContactPersonDropdownExpanded: false,
                                    });
                                  }
                                }}
                                tabIndex={0}
                                role="button"
                                aria-label="Lägg till tolkanvändare"
                                id="tv-dropdown__booknow__footer-conactperson__id"
                              >
                                + Lägg till tolkanvändare
                              </span>
                            }
                            ariaLabel={Translate({
                              content: 'bookNow.contactPersonUponInterpretor',
                            })}
                          />
                          {showContactPersonUponInterpertationError && (
                            <span className="tv-form__error text-justify">
                              E-post krävs.
                              <span
                                onClick={e => {
                                  e.stopPropagation();
                                  this.handleContactPersonModal(
                                    showContactPersonUponInterpertationError,
                                    'ContactPersonUponInterpretation',
                                    contactPersonUponInterpretation.value,
                                  );
                                }}
                                className="contact-person-email-edit"
                              >
                                + Lägg till här
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6 p-2">
                        <div className="form-group">
                          <label htmlFor="contactPerson">
                            {Translate({ content: 'bookNow.contactPerson' })}
                          </label>
                          <DropdownList
                            opened={
                              this.state.isContactOrderPersonDropdownExpanded
                            }
                            onClose={this.handleCloseOrderContactPerson}
                            onOpen={this.handleOpenOrderContactPerson}
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="contactPerson"
                            required
                            value={this.getValueFromList(
                              contactPerson.value,
                              contactPersons,
                            )}
                            defaultItem={{
                              name: 'Välj Beställare',
                              value: '',
                            }}
                            className={`ellipsis pl-3 py-1 tv-single-assign-form__control tv-single-assign-form__control-order-contact-person ${
                              showContactPersonError
                                ? 'tv-form__control--error'
                                : ''
                            }`}
                            options={contactPersons}
                            validators={['required']}
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content: 'bookNow.contactPerson',
                                  }),
                                },
                              }),
                            ]}
                            footer={
                              <span
                                onClick={e => {
                                  e.stopPropagation();
                                  this.handleContactPersonModal(
                                    showContactPersonError,
                                    'ContactPersonOrderer',
                                  );
                                  this.setState({
                                    isContactOrderPersonDropdownExpanded: false,
                                  });
                                }}
                                className="tv-dropdown__footer-conactperson-add tv-dropdown__footer-conactperson-add-ordercontactPerson"
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.handleContactPersonModal(
                                      showContactPersonError,
                                      'ContactPersonOrderer',
                                    );
                                    this.setState({
                                      isContactOrderPersonDropdownExpanded: false,
                                    });
                                  }
                                }}
                                tabIndex={0}
                                role="button"
                                aria-label="Lägg till beställare"
                                id="tv-dropdown__footer-orderconactperson__id"
                              >
                                + Lägg till beställare
                              </span>
                            }
                            ariaLabel={Translate({
                              content: 'bookNow.contactPerson',
                            })}
                          />
                          {showContactPersonError && (
                            <span className="tv-form__error text-justify">
                              E-post krävs.
                              <span
                                onClick={e => {
                                  e.stopPropagation();
                                  this.handleContactPersonModal(
                                    showContactPersonError,
                                    'ContactPersonOrderer',
                                    contactPerson.value,
                                  );
                                }}
                                className="contact-person-email-edit"
                              >
                                + Lägg till här
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6 p-2">
                        <div className="form-group">
                          <label>
                            <Translate content="singleAssignment.genderRequirement" />
                          </label>

                          <DropdownList
                            className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                            name="genderValue"
                            options={gender}
                            value={this.getValueFromList(
                              genderValue ? genderValue.value : null,
                              gender,
                            )}
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            ariaLabel={Translate({
                              content: 'singleAssignment.genderRequirement',
                            })}
                          />
                          {genderValue &&
                            genderValue?.value != '' &&
                            genderValue?.value != '0' && (
                              <div className="d-flex ">
                                <TFVRadioButton
                                  text={Translate({
                                    content: 'singleAssignment.preferChoice',
                                  })}
                                  name="genderRequirement"
                                  checked={!genderRequirement}
                                  value={1}
                                  onChange={e =>
                                    this.handleRadioButtonChange(
                                      e,
                                      'genderRequirement',
                                    )
                                  }
                                  className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                                />
                                <TFVRadioButton
                                  text={Translate({
                                    content: 'singleAssignment.choiceRequired',
                                  })}
                                  onChange={e =>
                                    this.handleRadioButtonChange(
                                      e,
                                      'genderRequirement',
                                    )
                                  }
                                  name="genderRequirement"
                                  value={2}
                                  checked={genderRequirement}
                                  className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      {showLMANumber && (
                        <div className="col-12 col-md-6 col-xl-6 p-2">
                          <div className="form-group">
                            <label>
                              <Translate content="bookNow.LMANumber" />
                            </label>
                            <TextField
                              placeholder={Translate({
                                content: 'bookNow.lmaNumberPlaceholder',
                              })}
                              maxLength={100}
                              className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                              aria-label="LMA Number"
                              name="lmaNumber"
                              id="lmaNumber"
                              onChange={this.handleChange}
                              value={lmaNumber}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row tv-booknow_row">
                      <div className="col-12 col-md-6 col-xl-6 p-2">
                        <div className="form-group">
                          <label>
                            {Translate({ content: 'bookNow.unit' })}
                          </label>
                          <div className="tv-booknow__text-unit">
                            {customerName}
                          </div>
                        </div>
                      </div>
                      {validCustomerInvoices.length > 0 && (
                        <div className="col-12 col-md-6 col-xl-6 p-2">
                          <div className="form-group">
                            <div className="d-flex flex-row">
                              <label htmlFor="validCustomerInvoice">
                                <Translate content="singleAssignment.validCustomerInvoice" />
                              </label>
                              <div className="tooltip-icon">
                                <i
                                  class="fa fa-question-circle ml-2"
                                  aria-hidden="true"
                                ></i>
                                <span className="tooltip-text">
                                  Välj den vårdcentralen patienten tillhör.
                                </span>
                              </div>
                            </div>

                            <DropdownList
                              className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                              name="validCustomerInvoice"
                              options={validCustomerInvoices}
                              value={this.getValueFromList(
                                validCustomerInvoice
                                  ? validCustomerInvoice.value
                                  : null,
                                validCustomerInvoices,
                              )}
                              onChange={e => {
                                this.handleChange(e);
                              }}
                              validators={['required']}
                              ariaLabel={Translate({
                                content:
                                  'singleAssignment.validCustomerInvoice',
                              })}
                              errorMessages={[
                                Translate({
                                  content: 'validator.isRequired',
                                  params: {
                                    name: Translate({
                                      content:
                                        'singleAssignment.validCustomerInvoice',
                                    }),
                                  },
                                }),
                              ]}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <div className="tv-booknow__form__buttons justify-content-between tv-display-flex">
                        <TFVButton
                          type="line-primary"
                          text={Translate({
                            content: 'bookNow.cancelButton',
                          })}
                          // onClick={this.cancelModalHandler}
                          onClick={() => {
                            removeContextualClassForBooknow();
                            onCloseClicked();
                          }}
                        />
                        <TFVButton
                          buttonType="submit"
                          type="secondary"
                          disabled={
                            !moveForward || dateRange.startTime < moment()
                          }
                          text={Translate({
                            content: 'bookNow.submitButton',
                          })}
                        />
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            )}
          {bookDirectUnavailable && (
            <>
              <h1 className="tv-available-interpreters__title booking">
                Det är ej möjligt att använda tjänsten Akut Tolk under denna
                tidpunkt.
              </h1>
              <div className="tv-available-interpreters__message">
                Akut Tolk är tillgängligt dagtid på vardagar
              </div>
              <div className="tv-booknow__form__buttons justify-content-between tv-display-flex">
                <TFVButton
                  type="line-primary"
                  text={Translate({
                    content: 'bookNow.cancelButton',
                  })}
                  onClick={onCloseClicked}
                />
              </div>
            </>
          )}
          {visibleAvailabilityInterpretorModal &&
            !visibleCompletionModal &&
            showPreview && (
              <AvailableInterpreters
                data={availableResourcesAutoAssign.TimeSlots}
                visibleRetryMessage={visibleRetryMessage}
                onSubmit={this.createBooking}
                onGoBack={this.goBackHandler}
              />
            )}
          {showPreview &&
            visibleCompletionModal &&
            !visibleAvailabilityInterpretorModal && (
              <OrderComplete
                orderNumber={returnedOrder.ReturnedAutoAssignOrder.OrderNumber}
                dateRange={{
                  TimeFrom:
                    returnedOrder.ReturnedAutoAssignOrder.DateTimeOrderFrom,
                  TimeTo: returnedOrder.ReturnedAutoAssignOrder.DateTimeOrderTo,
                }}
                language={language}
                customerName={customerName}
                bookedInterpreterName={
                  returnedOrder.ReturnedAutoAssignOrder.ResourceFullName
                }
                bookedInterpreterNumber={
                  returnedOrder.ReturnedAutoAssignOrder.ResourcePhoneNumber
                }
                onClose={this.createNewBookingAfterSuccess}
                onSubmit={this.redirectToCalendar}
                showInfo={isTelefontolkning}
              />
            )}
          {showPreview &&
            !visibleAvailabilityInterpretorModal &&
            !visibleCompletionModal && (
              <PreviewEmgBooking
                ref={this.componentRef}
                dateRange={{
                  TimeFrom: moment(dateRange.startTime).format(),
                  TimeTo: moment(dateRange.endTime).format(),
                }}
                language={language}
                bookedInterpreterName={`${contactPersonUponInterpretation.FirstName} ${contactPersonUponInterpretation.LastName}`}
                bookedInterpreterNumber={
                  contactPersonUponInterpretation.PhoneNumberDirect
                }
                customerName={`${contactPerson.FirstName} ${contactPerson.LastName}`}
                onClose={this.goBackHandler}
                onSubmit={this.handleSubmitPreview}
                genderValue={genderValue}
                genderRequirement={genderRequirement}
                validCustomerInvoice={validCustomerInvoice.name}
                lmaNumber={lmaNumber}
              />
            )}
        </Modal>
        {showConfirmUnavailableModal && (
          <ConfirmUnavailableLanguage
            onCloseClicked={this.closeUnavailableLanguageModal}
            textShow={
              <>
                <p>
                  <b>Tyvärr finns det ingen tillgänglig tolk</b>
                </p>
                <p>
                  Tyvärr finns ingen tillgänglig tolk din önskade tid eller
                  angränsade tider, försök gärna senare.
                </p>
                <p>
                  För hjälp från en tolkförmedlare, ring oss på{' '}
                  <b className="telephone-number">0770-813 000</b> vardagar kl.
                  07.30 - 17.30.
                </p>
              </>
            }
          />
        )}
        <CancelModal
          isVisible={visibleCancelModal}
          onCloseClicked={() => {
            onCloseClicked();
            this.completionModalHandler();
          }}
          onModalCloseClicked={() => {
            this.onCancelModalCloseClicked();
          }}
          onOverlayClick={this.cancelModalHandler}
          onSubmitClick={this.cancelModalHandler}
        />
      </>
    );
  }
}

BookNowModal.propTypes = {
  isVisible: PropTypes.bool,
  onCloseClicked: PropTypes.func,
  onOverlayClick: PropTypes.func,
  bookDirectOpeningHours: PropTypes.shape({}),
  fetchSkills: PropTypes.func.isRequired,
  validCustomerInvoices: PropTypes.arrayOf(PropTypes.shape({})),
  isTelephoneTalking: PropTypes.bool,
  doAllowLMANumberOnConnectedContract: PropTypes.bool,
};
BookNowModal.defaultProps = {
  isVisible: false,
  onCloseClicked: () => {},
  onOverlayClick: () => {},
  bookDirectOpeningHours: {},
  validCustomerInvoices: [],
  isTelephoneTalking: false,
  doAllowLMANumberOnConnectedContract: false,
};

const mapStateToProps = state => {
  const assignment = state.workAssignment.assignmentQuickOrder;
  const {
    availableResourcesAutoAssign,
    availableResourcesAutoAssignPending,
    assignOrderError,
  } = state.workAssignment;
  const contactPersons = state.user.contactPersons || [];
  const {
    customerName,
    ContactPersonOrdererRequiresEmail,
    ContactPersonUponInterpretationRequiresEmail,
  } = state.user.profile;
  const userProfile = state.user.profile;
  const {
    bookDirectServiceID,
    isTelephoneTalking,
    doAllowLMANumberOnConnectedContract,
  } = state.user;
  const { returnedOrder } = state.workAssignment;

  // const newContactPersons = state.workAssignment.contactPersons || [];
  // const profile = state.user.profile || [];
  const skills = state.skill.availableSkills || [];
  const languages = skills.map((item, i) => ({
    value: item.SkillIdentifier,
    name: item.SkillName,
    isAvailable: item.IsAvailable,
  }));
  const validCustomerInvoices =
    userProfile.ValidCustomerInvoice?.map(item => ({
      name: item.CustomerInvoiceName,
      value: item.CustomerInvoiceIdentifier,
    })) || [];
  return {
    assignment,
    skills,
    contactPersons,
    customerName,
    // newContactPersons,
    // profile,
    languages,
    availableResourcesAutoAssign,
    availableResourcesAutoAssignPending,
    userProfile,
    returnedOrder,
    bookDirectServiceID,
    assignOrderError,
    ContactPersonOrdererRequiresEmail,
    ContactPersonUponInterpretationRequiresEmail,
    validCustomerInvoices,
    isTelephoneTalking,
    doAllowLMANumberOnConnectedContract,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchResourcesAutoAssign: (
    ServiceIdentifier,
    SkillIdentifier,
    DatetimeFrom,
    DatetimeTo,
    sessionIdentifier,
    DoRequireCorrectGender,
    GenderIdPreferred,
  ) => {
    dispatch(
      availableResourceAutoAssign(
        ServiceIdentifier,
        SkillIdentifier,
        DatetimeFrom,
        DatetimeTo,
        sessionIdentifier,
        DoRequireCorrectGender,
        GenderIdPreferred,
      ),
    );
  },
  createAutoAssignOrder: (
    ServiceIdentifier,
    contactPerson,
    contactPersonUponInterpretation,
    language,
    dateRange,
    customer,
    sessionIdentifier,
    DoRequireCorrectGender,
    GenderIdPreferred,
    CustomerInvoiceIdentifier,
    LMANumber,
  ) => {
    dispatch(
      autoAssignCreateRequest(
        ServiceIdentifier,
        contactPerson,
        contactPersonUponInterpretation,
        language,
        dateRange,
        customer,
        sessionIdentifier,
        DoRequireCorrectGender,
        GenderIdPreferred,
        CustomerInvoiceIdentifier,
        LMANumber,
      ),
    );
  },
  clearAutoAssignResources: () => {
    dispatch(clearAutoAssignResources());
  },
  onGetContactPersons: () => {
    dispatch(fetchContactPersons());
  },
  executeGetServices: (params, callback) => {
    dispatch(fetchServices(params, callback));
  },
  getAutoAssignOpeningHours: dateObj => {
    dispatch(autoAssignOpeningHours(dateObj));
  },
  fetchSkills: () => {
    dispatch(fetchAvailableSkills());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(BookNowModal);

const TFVRadioButton = ({
  className,
  name,
  onChange,
  checked,
  value,
  text = '',
  disabled = false,
}) => (
  <label className={`tv-form__checkbox-container ${className}`}>
    <input
      disabled={disabled}
      type="radio"
      name={name}
      onChange={onChange}
      defaultChecked={checked}
      value={value}
      tabIndex={0}
    />
    <span className="tv-form__checkbox-checkmark" />
    {text}
  </label>
);
