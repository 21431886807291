import React from 'react';
import { Modal, TFVButton, Translate } from '../../../Share/components';
import './style.scss';
import { isIE } from '../../../Share/utils';
import PropTypes from 'prop-types';

const CopyBookingConfirmation = ({ isVisble, onClose, onConfirm }) => {
  return (
    <React.Fragment>
      <Modal
        className="tv-copybooking-confirmation__modal"
        onCloseClicked={onClose}
        isVisible={isVisble}
        ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        headerText={Translate({
          content: 'copyBookingConfirmation.title',
        })}
        headerClassName="tv-copybooking-confirmation__modal__header"
      >
        <>
          <div className="tv-copybooking-confirmation__modal__description">
            <Translate content="copyBookingConfirmation.description" />
          </div>
          <div className="d-flex flex-column">
            <div className="tv-workassignment__detail-buttons tv-display-flex">
              <TFVButton
                type="secondary"
                text={Translate({
                  content: 'copyBookingConfirmation.confirm',
                })}
                onClick={onConfirm}
              />
              <TFVButton
                type="line-primary"
                className="tv-buttons__element-link"
                text={Translate({
                  content: 'copyBookingConfirmation.cancel',
                })}
                onClick={onClose}
              />
            </div>
          </div>
        </>
      </Modal>
    </React.Fragment>
  );
};

const propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isVisble: PropTypes.bool,
};

const defaultProps = {
  onClose: () => {},
  onConfirm: () => {},
  isVisble: true,
};

CopyBookingConfirmation.propTypes = propTypes;
CopyBookingConfirmation.defaultProps = defaultProps;

export default CopyBookingConfirmation;
