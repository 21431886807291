/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-form-validator-core';
import { connect } from 'react-redux';
import { isEmpty, isObject, clone } from 'lodash/core';
import { unionWith, isEqual } from 'lodash';
import { fetchContactPersons, fetchServices } from '../../actions/user';
import { getXTimefromSpecificDate } from '../../../Share/utils/dateUtil';
import {
  hideNewBooking,
  onUpdateNewAssignmentsTemp,
  UpdateContactPersons,
} from '../../actions';
import {
  createDefaultAssignment,
  createDefaultContactPerson,
  generateUniqueId,
  isAddressEdited,
  isContactPersonValid,
  isIE,
  removeContextualClassForBooknow,
  selectDefaultService,
} from '../../../Share/utils';
import {
  SINGLE_FORM,
  MULTI_FORM,
  ASSIGNMENT_TYPES,
} from '../../../Share/constants';
import moment from 'moment';
import QuickOrderForm from './QuickOrderForm';
import history from '../../../Share/utils/history';
import ContactPersonModal from '../NewAssignment/ContactPersonModal';
import './style.scss';
import { PreviewAssignment } from '../NewAssignment';
import SuccessMessage from '../NewAssignment/SuccessMessage';
import { subscribe, unSubscribe } from '../../actions/eventBus';
import AdvancedAssigmentWizard from '../NewAssignment/AdvancedAssigmentWizard';
import {
  Modal,
  Translate,
  LeftDrawerSlide,
  Dialog,
  RouteLeavingModal,
} from '../../../Share/components';
import { fallbacks } from 'i18n-js';
import { ContactPersonEdit } from '..';
import { createRef } from 'react';
import ConfirmDuplicateOrder from '../NewAssignment/ConfirmDuplicateOrder';
import { createNewOrder, updateOrder } from '../../actions';
import { setEmptyCreateNewOrderErrors } from '../../actions/workAssignment';

const propTypes = {
  assignment: PropTypes.arrayOf(PropTypes.shape({})),
  skills: PropTypes.arrayOf(PropTypes.shape({})),
  alternativeLanguages: PropTypes.shape({}),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  profile: PropTypes.shape({}),
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  executeGetContactPersons: PropTypes.func,
  executeGetServices: PropTypes.func,
  onSwitchToDetailOrder: PropTypes.func,
  date: PropTypes.string,
  onClose: PropTypes.func,
  showQuickOrder: PropTypes.bool,
  RouteLeavingGuardHandler: PropTypes.func,
  onCreateNewOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  onUpdateCreatedOrderErrors: PropTypes.func,
  customerDefaultAddress: PropTypes.shape({}),
  onHideNewBooking: PropTypes.func,
  validCustomerInvoices: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  assignment: {},
  skills: [],
  services: [],
  contactPersons: [],
  profile: {},
  languages: [],
  alternativeLanguages: {},
  executeGetContactPersons: () => {},
  executeGetServices: () => {},
  onSwitchToDetailOrder: () => {},
  onClose: () => {},
  date: null,
  showQuickOrder: false,
  RouteLeavingGuardHandler: () => {},
  onCreateNewOrder: () => {},
  onUpdateOrder: () => {},
  onUpdateCreatedOrderErrors: () => {},
  customerDefaultAddress: {},
  onHideNewBooking: () => {},
  validCustomerInvoices: [],
};

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

ValidatorForm.addValidationRule('urlformat', value => {
  if (value && value == '') return true;
  if (value && (value.startsWith('http://') || value.startsWith('https://'))) {
    return true;
  }
  return false;
});

ValidatorForm.addValidationRule('phoneNumberFormat', value => {
  if (value && value == '') return true;
  if (
    value &&
    (value.startsWith('07') ||
      value.startsWith('00467') ||
      value.startsWith('+467'))
  ) {
    return true;
  }
  return false;
});

class QuickOrder extends Component {
  constructor(props) {
    super(props);
    this.originalTitle = document.title;
    const {
      assignmentTemp,
      alternativeLanguages,
      skills,
      services,
      contactPersons,
      profile,
      onResetDate,
      date,
      validCustomerInvoices,
    } = props;
    let currentContactPerson = null;
    contactPersons.forEach(person => {
      if (person.IsCurrentlyLoggedIn === true) {
        currentContactPerson = person;
      }
    });
    let contactPersonsList = clone(contactPersons);
    let confirmationEmail = 'test@gmail.com';
    if (props.newContactPersons.length) {
      contactPersonsList = unionWith(
        contactPersonsList,
        props.newContactPersons,
        isEqual,
      );
    }
    if (isEmpty(assignmentTemp)) {
      this.state = {
        assignment: {
          ...createDefaultAssignment(
            SINGLE_FORM,
            skills,
            services,
            contactPersonsList,
            profile,
            date,
            confirmationEmail,
            currentContactPerson,
            validCustomerInvoices,
          ),
        },
        contactPerson: createDefaultContactPerson(),
        showContactPersonModal: false,
        showPreview: false,
        showSuccessMessage: false,
        showAdvancedOrderWizard: false,
        successMessageDetails: [],
        visibleRouteLeavingModal: false,
        isAddDemands: false,
        isAltLanguageAdded: false,
        alternativeLanguages: [],
        confirmationEmail: 'test@gmail.com',
        isAlternativeAddressAdded: false,
        alternativeAddress: {},
        showDuplicateOrder: false,
        duplicateAssignmentDetails: {},
        errorMessage: '',
        isDateValid: true,
        isDefaultDateUpdated: false,
        isTimeValid: true,
        contactPersonFocus: false,
        contactOrderPersonFocus: false,
        languageFocus: false,
        checkIsPatientConsentGivenin: true,
      };
    } else {
      this.state = {
        assignment: {
          ...assignmentTemp,
          languages: skills,
        },
        contactPerson: createDefaultContactPerson(),
        showContactPersonModal: false,
        showPreview: false,
        showSuccessMessage: false,
        showAdvancedOrderWizard: false,
        successMessageDetails: [],
        visibleRouteLeavingModal: false,
        isAddDemands: false,
        alternativeLanguages:
          alternativeLanguages[assignmentTemp.language.value] || [],
        isAltLanguageAdded: !!assignmentTemp.alternativeLanguage.value,
        showDuplicateOrder: false,
        duplicateAssignmentDetails: {},
        errorMessage: '',
        isDateValid: true,
        isDefaultDateUpdated: false,
        isTimeValid: true,
        contactPersonFocus: false,
        contactOrderPersonFocus: false,
        languageFocus: false,
        checkIsPatientConsentGivenin: true,
      };
    }
    onResetDate();
  }

  componentDidMount() {
    document.title = 'Boka tolk - TFV Kundportal';
    const { assignment, isAlternativeAddressAdded } = this.state;
    const { executeGetContactPersons, executeGetServices, languages } =
      this.props;
    executeGetContactPersons();

    const { startTime, endTime } = assignment.dateRange;
    const { language, isEdit, addressLine, city, postalCode } = assignment;
    if (isEdit) {
      this.setState({
        isAlternativeAddressAdded: true,
      });
    }
    if (addressLine && city && postalCode) {
      this.setState({
        isAlternativeAddressAdded: true,
      });
    }

    const callbackUpdateServices = (allowSelectServices, _assignment) => {
      const validServicesForOrders = allowSelectServices.filter(
        service =>
          service.CanBeUsedForAutoAssignOrder === false &&
          service.IsTranslation === false,
      );
      let selectedService = this.selectDefaultAssignmentType(
        validServicesForOrders,
      );
      if (
        _assignment.typeOfAssignment &&
        _assignment.typeOfAssignment.value &&
        _assignment.typeOfAssignment.value != ''
      ) {
        const matchingServices = validServicesForOrders.filter(
          x => x.ServiceIdentifier == _assignment.typeOfAssignment.value,
        );
        selectedService =
          matchingServices.length > 0 ? matchingServices[0] : selectedService;
      }
      this.setState({
        assignment: {
          ...this.state.assignment,
          services: validServicesForOrders,
          typeOfAssignment:
            _assignment.typeOfAssignment == '' ||
            _assignment.typeOfAssignment.value == null
              ? selectDefaultService(validServicesForOrders)
              : _assignment.typeOfAssignment !== '' &&
                _assignment.typeOfAssignment &&
                !isEmpty(_assignment.typeOfAssignment)
              ? _assignment.typeOfAssignment
              : selectDefaultService(validServicesForOrders),
          // typeOfAssignment:
          //   _assignment.typeOfAssignment !== '' &&
          //   _assignment.typeOfAssignment &&
          //   !isEmpty(_assignment.typeOfAssignment)
          //     ? _assignment.typeOfAssignment
          //     : selectDefaultService(allowSelectServices),
          selectedAssignmentTypeData: [selectedService],
          isBankIDRequired: false,
        },
      });
    };

    if (!isEmpty(languages)) {
      const params = {
        language: assignment.language
          ? assignment.language.value == '-' || assignment.language.value == ''
            ? languages[1].value
            : assignment.language.value
            ? assignment.language.value
            : languages[1].value
          : languages[1].value,
        startTime,
        endTime,
        assignment,
      };
      executeGetServices(params, callbackUpdateServices);
    }

    ValidatorForm.addValidationRule('checkNumLength', (value, number) => {
      if (value.toString().length !== Number(number)) {
        return false;
      }
      return true;
    });
    subscribe('SuccessFullOrderCreation', ({ isBasic, assignmentDetails }) => {
      if (isBasic) {
        this.setState({
          showPreview: false,
          showSuccessMessage: true,
          successMessageDetails: assignmentDetails,
        });
      }
    });
    this.creteContactEvent = subscribe(
      'SuccessfullyContactPersonCreate',
      ({ ContactPersonIdentifier, type }) => {
        const addContatctPerson = {};
        if (type == 'ContactPersonOrderer') {
          addContatctPerson.orderContactPerson = ContactPersonIdentifier;
        } else {
          addContatctPerson.contactPerson = ContactPersonIdentifier;
        }
        this.setState({
          assignment: {
            ...this.state.assignment,
            ...addContatctPerson,
          },
        });
      },
    );
    document.addEventListener('keydown', this.handleKeyDown);
  }
  componentDidUpdate() {
    const { assignment } = this.state;

    if (assignment.isEdit) {
      document.title = 'Ändra ' + assignment.OrderNumber + ' - TFV Kundportal';
    }
    if (assignment.isCopyOrder) {
      document.title = 'Boka tolk - TFV Kundportal';
    }
  }
  componentWillUnmount() {
    document.title = 'Översikt - TFV Kundportal';
    unSubscribe('SuccessfullyContactPersonCreate', this.creteContactEvent);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.date &&
      prevState.assignment.dateRange &&
      prevState.assignment.dateRange.ranges.startDate !== nextProps.date
    ) {
      const tempAssignment = {
        ...prevState.assignment,
        dateRange: {
          ranges: {
            endDate: nextProps.date,
            startDate: nextProps.date,
            key: prevState.assignment.dateRange.ranges.key,
          },
          startTime: prevState.assignment.dateRange.startTime,
          endTime: prevState.assignment.dateRange.endTime,
          durationDifference: prevState.assignment.dateRange.durationDifference,
        },
      };
      nextProps.onResetDate();
      return {
        assignment: tempAssignment,
      };
    }

    if (
      !isEqual(prevState.assignment.contactPersons, nextProps.contactPersons)
    ) {
      return {
        assignment: {
          ...prevState.assignment,
          contactPersons: nextProps.contactPersons,
        },
      };
    }

    if (prevState.assignment.services.length !== nextProps.services.length) {
      return {
        assignment: {
          ...prevState.assignment,
        },
      };
    }

    // if (isEmpty(prevState.assignment.profile)) {
    //   if (
    //     nextProps.profile.customerIdentifier !== '' &&
    //     nextProps.profile.customerNumber !== '' &&
    //     !isEmpty(nextProps.profile.customerAddress) &&
    //     !prevState.assignment.isEdit &&
    //     prevState.assignment.isCopy
    //   ) {
    //     return {
    //       assignment: {
    //         ...prevState.assignment,
    //         profile: nextProps.assignmentTemp.profile || {},
    //         addressLine: nextProps.assignmentTemp.addressLine || '',
    //         city: nextProps.assignmentTemp.city || '',
    //         district: nextProps.assignmentTemp.district || '',
    //         postalCode: nextProps.assignmentTemp.postalCode.toString(),
    //         orderCompanyName: nextProps.assignmentTemp.orderCompanyName,
    //         orderLocation: `${
    //           !nextProps.profile.customerAddress.CareOf
    //             ? ''
    //             : `${nextProps.profile.customerAddress.CareOf}, `
    //         }${nextProps.profile.customerAddress.AddressRow1}, ${
    //           nextProps.profile.customerAddress.PostalCode
    //         }, ${nextProps.profile.customerAddress.City}`,
    //       },
    //     };
    //   }
    //   if (
    //     nextProps.profile.customerIdentifier !== '' &&
    //     nextProps.profile.customerNumber !== '' &&
    //     !isEmpty(nextProps.profile.customerAddress) &&
    //     !prevState.assignment.isEdit
    //   ) {
    //     return {
    //       assignment: {
    //         ...prevState.assignment,
    //         profile: nextProps.profile || {},
    //         addressLine: nextProps.profile.customerAddress.AddressRow1 || '',
    //         city: nextProps.profile.customerAddress.City || '',
    //         district: nextProps.profile.customerAddress.CareOf || '',
    //         postalCode: nextProps.profile.customerAddress.PostalCode.toString(),
    //         orderCompanyName: nextProps.profile.customerName,
    //         orderLocation: `${
    //           !nextProps.profile.customerAddress.CareOf
    //             ? ''
    //             : `${nextProps.profile.customerAddress.CareOf}, `
    //         }${nextProps.profile.customerAddress.AddressRow1}, ${
    //           nextProps.profile.customerAddress.PostalCode
    //         }, ${nextProps.profile.customerAddress.City}`,
    //       },
    //     };
    //   }
    // }
    return null;
  }

  ConvergeValidate = () => {
    const { assignment, isTimeValid } = this.state;
    if (!assignment.dateRange.ranges.startDate) {
      this.setState({
        isDateValid: false,
      });
    }
    let isValid = true;
    const selectedDate =
      assignment.dateRange.ranges.startDate.format('DD/MM/YYYY');
    const todayDate = moment().format('DD/MM/YYYY');
    if (selectedDate === todayDate && !assignment.isEdit) isValid = false;
    if (isTimeValid === false) isValid = false;
    return isValid;
  };

  handleSubmit = () => {
    const { assignment, showPreview, isAddDemands } = this.state;
    const { profile } = this.props;
    let isValid = true;
    let validContactPersonUponInterpretation = true;
    let validContactPersonOrderer = true;
    let isPatientConsentGivenin = true;

    if (this.ConvergeValidate()) {
      if (
        !isContactPersonValid(
          profile.ContactPersonUponInterpretationRequiresEmail,
          assignment.contactPerson,
          assignment.contactPersons,
        )
      ) {
        validContactPersonUponInterpretation = false;
      }
      if (
        !isContactPersonValid(
          profile.ContactPersonOrdererRequiresEmail,
          assignment.orderContactPerson,
          assignment.contactPersons,
        )
      ) {
        validContactPersonOrderer = false;
      }
      if (
        assignment.doIncludeAdditionalServices &&
        !assignment.isPatientConsentGivenin
      ) {
        isPatientConsentGivenin = false;
        this.setState({
          checkIsPatientConsentGivenin: false,
        });
      }
      isValid =
        validContactPersonUponInterpretation &&
        validContactPersonOrderer &&
        isPatientConsentGivenin;

      this.props.onSwitchToDetailOrder({
        ...assignment,
        isAddressEdited: isAddressEdited(assignment, profile),
      });
      if (isValid) {
        if (!isAddDemands) {
          this.setState({
            showPreview: true,
          });
        } else {
          this.setState({ showAdvancedOrderWizard: true, isAddDemands: false });
        }
      }
    }
  };
  quickOrderRef = createRef();
  handleSwitchToMultipleOrder = () => {
    const { assignment } = this.state;

    const { skills, services, contactPersons, profile } = this.props;

    const multiFormObj = createDefaultAssignment(
      MULTI_FORM,
      skills,
      services,
      contactPersons,
      profile,
    );
    multiFormObj.form = {
      ...multiFormObj.form,
      serialDateRanges: assignment.serialDateRanges,
      dateRange: assignment.dateRange,
      contactPerson: assignment.contactPerson,
      contactNumber: assignment.contactNumber,
      contactEmail: assignment.contactEmail,
      contactDirectNumber: assignment.contactDirectNumber,
      orderContactPerson: assignment.orderContactPerson,
      orderContactNumber: assignment.orderContactNumber,
      orderContactDirectNumber: assignment.orderContactDirectNumber,
      orderEmail: assignment.orderEmail,
      typeOfAssignment: assignment.typeOfAssignment,
      language: assignment.language,
      alternativeLanguage: assignment.alternativeLanguage,
    };

    if (this.ConvergeValidate()) {
      this.props.onSwitchToDetailOrder([multiFormObj]);
      history.push('/advanced-order-form');
    }
  };

  changeTimeOnType = assignmentValue => {
    const { assignment } = this.state;
    const { dateRange } = assignment;
    const clonedDateRange = clone(dateRange);
    if (assignmentValue === ASSIGNMENT_TYPES.TELEPHONE) {
      clonedDateRange.endTime = getXTimefromSpecificDate(
        dateRange.startTime,
        30,
        'minutes',
      );
    } else {
      clonedDateRange.endTime = getXTimefromSpecificDate(
        dateRange.startTime,
        60,
        'minutes',
      );
    }
    return clonedDateRange.endTime;
  };

  resetMeetingRelatedData() {
    return {
      meetingLink: null,
      meetingPassCode: null,
      meetingPlatform: { name: null, value: null },
      phoneNumber: null,
      isBankIDRequired: null,
    };
  }
  selectDefaultAssignmentType = services => {
    const filteredTeleService = services.filter(x =>
      x.ServiceName.includes('Tele'),
    );
    if (filteredTeleService.length === 0) return services[0];
    return filteredTeleService[0];
  };

  addAltLanguage = action => {
    this.setState({
      isAltLanguageAdded: action,
      assignment: {
        ...this.state.assignment,
        alternativeLanguage: action
          ? this.state.assignment.alternativeLanguage
          : { name: null, value: null },
      },
    });
  };

  addAlternativeAddress = action => {
    this.setState({
      isAlternativeAddressAdded: action,
      assignment: {
        ...this.state.assignment,
        isDeviatingAddressPerforming: action,
        addressLine: action ? this.state.assignment.addressLine : '',
        city: action ? this.state.assignment.city : '',
        postalCode: action ? this.state.assignment.postalCode : '',
        isHomeAddress: action ? this.state.assignment.isHomeAddress : false,
      },
    });
  };

  handleChange = obj => {
    const { alternativeLanguages } = this.props;
    const { assignment } = this.state;
    const { services } = assignment;

    if (obj.dateRange) {
      if (obj.dateRange.ranges.startDate !== null) {
        this.setState({
          isDateValid: true,
        });
        if (
          moment(obj.dateRange.ranges.startDate).isSame(
            moment().add(1, 'days'),
            'day',
          )
        ) {
          const time = moment(obj.dateRange.startTime).add(1, 'day');
          const startTime = moment().add(1, 'day').startOf('day');
          const endTime = moment().add(1, 'day').startOf('day').add(8, 'hours');
          if (
            time.isBetween(moment(startTime), moment(endTime)) ||
            time.isSame(moment(startTime))
          ) {
            this.setState({
              isTimeValid: false,
            });
          } else {
            this.setState({
              isTimeValid: true,
            });
          }
        } else {
          this.setState({
            isTimeValid: true,
          });
        }
      }
    }

    if (obj.isPatientConsentGivenin) {
      if (obj.isPatientConsentGivenin === true) {
        this.setState({
          checkIsPatientConsentGivenin: true,
        });
      }
    }

    if (obj.language) {
      this.setState({
        alternativeLanguages: alternativeLanguages[obj.language.value] || [],
        isAltLanguageAdded: false,
      });
    }

    let filteredItems = services.filter(x => {
      if (obj.typeOfAssignment === undefined) {
        return x.ServiceIdentifier === assignment.typeOfAssignment.value;
      }
      return x.ServiceIdentifier === obj.typeOfAssignment.value;
    });

    if (obj.typeOfAssignment?.isMessageService === true) {
      this.state.assignment.dateRange.endTime = moment(
        this.state.assignment.dateRange.startTime,
      ).add(15, 'minutes');
      this.state.assignment.dateRange.durationDifference = 15;
    }

    const { executeGetServices, languages } = this.props;

    const isChangeLanguage =
      obj.language !== null &&
      obj.language !== undefined &&
      obj.language !== '';
    const assignmentStartTime = assignment.dateRange.ranges.startDate;
    const assignmentEndTime = assignment.dateRange.ranges.endDate;
    if (
      (isChangeLanguage || obj.isChangeSerialDuration) &&
      (assignmentStartTime === null || assignmentEndTime === null)
    ) {
      const { startTime, endTime } = assignment.dateRange;
      const callbackUpdateServices = allowSelectServices => {
        if (allowSelectServices && allowSelectServices.length) {
          let typeOfAssignment = allowSelectServices[0].ServiceIdentifier;

          allowSelectServices.forEach(allowSelect => {
            if (assignment.typeOfAssignment.value !== '') {
              if (
                allowSelect.ServiceIdentifier ===
                assignment.typeOfAssignment.value
              ) {
                // keep the same type of Assignment
                // typeOfAssignment = assignment.typeOfAssignment;
                typeOfAssignment = {
                  name: allowSelect.ServiceName,
                  value: allowSelect.ServiceIdentifier,
                  isMessageService: allowSelect.IsMessageService,
                  doAllowLMANumberOnConnectedContract:
                    allowSelect.DoAllowLMANumberOnConnectedContract,
                  isPerformedRemotely: allowSelect.IsPerformedRemotely,
                  additionalServices: allowSelect.AdditionalServices,
                };
              }
            } else {
              typeOfAssignment = assignment.typeOfAssignment;
            }
          });

          const validServicesForOrders = allowSelectServices.filter(
            service =>
              service.CanBeUsedForAutoAssignOrder === false &&
              service.IsTranslation === false,
          );

          this.setState({
            assignment: {
              ...this.state.assignment,
              services: validServicesForOrders,
              typeOfAssignment,
              certificationLevels: undefined,
              doIncludeAdditionalServices: false,
              isPatientConsentGivenin: false,
              messageReceiverAdditionalService: '',
            },
          });
        } else {
          this.setState({
            assignment: {
              ...this.state.assignment,
              services: [],
              typeOfAssignment: {},
              certificationLevels: undefined,
            },
          });
        }
      };
      const params = {
        language: obj.language
          ? obj.language.value == ''
            ? languages[1].value
            : obj.language.value
          : assignment.language?.value != '' && assignment.language.value
          ? assignment.language.value
          : obj.language?.value
          ? obj.language.value
          : languages[1].value,
        startTime: startTime,
        endTime: endTime,
      };

      executeGetServices(params, callbackUpdateServices);
    }
    if (
      (isChangeLanguage || obj.isChangeSerialDuration) &&
      (assignment.dateRange.ranges.startDate ||
        assignment.dateRange.ranges.endDate)
    ) {
      let { startDate, endDate } = assignment.dateRange.ranges;
      const { startTime, endTime } = assignment.dateRange;
      if (obj.isChangeSerialDuration) {
        startDate = obj.dateRange.ranges.startDate;
        endDate = obj.dateRange.ranges.endDate;
      }
      startDate.set({
        hour: startTime.hours(),
        minute: startTime.minutes(),
      });
      endDate.set({ hour: endTime.hours(), minute: endTime.minutes() });
      // Checking the time - is from next day or current day
      const beginningTime = moment({
        h: startTime.hours(),
        s: startTime.minutes(),
      });
      const endingTime = moment({
        h: endTime.hours(),
        s: endTime.minutes(),
      });

      if (endingTime.isBefore(beginningTime)) {
        var endDateHour = endDate.hours();
        var endDateMinute = endDate.minutes();
        endDate = moment(startDate);
        endDate.hours(endDateHour).minutes(endDateMinute);
        endDate.add(1, 'day');
      } else if (endingTime.isSame(beginningTime)) {
        var endDateHour = endDate.hours();
        var endDateMinute = endDate.minutes();
        endDate = moment(startDate);
        endDate.hours(endDateHour).minutes(endDateMinute);
        endDate.add(1, 'day');
      } else {
        var endDateHour = endDate.hours();
        var endDateMinute = endDate.minutes();
        endDate = moment(startDate);
        endDate.hours(endDateHour).minutes(endDateMinute);
      }
      this.setState({
        assignment: {
          ...this.state.assignment,
          ...obj,
          alternativeLanguage: obj.language
            ? { name: null, value: null }
            : obj.alternativeLanguage
            ? obj.alternativeLanguage
            : this.state.assignment.alternativeLanguage,
          typeOfAssignment: obj.typeOfAssignment
            ? obj.typeOfAssignment
            : assignment.typeOfAssignment,
          dateRange: {
            startTime: obj.dateRange
              ? obj.dateRange.startTime
              : assignment.dateRange.startTime,
            endTime: obj.dateRange
              ? obj.dateRange.endTime
              : assignment.dateRange.endTime,
            ranges: obj.dateRange
              ? obj.dateRange.ranges
              : assignment.dateRange.ranges,
            durationDifference: obj.dateRange
              ? obj.dateRange.durationDifference
              : assignment.dateRange.durationDifference,
          },
          selectedAssignmentTypeData: filteredItems,
        },
      });

      const callbackUpdateServices = allowSelectServices => {
        if (allowSelectServices && allowSelectServices.length) {
          let typeOfAssignment = allowSelectServices[0].ServiceIdentifier;

          allowSelectServices.forEach(allowSelect => {
            if (assignment.typeOfAssignment.value !== '') {
              if (
                allowSelect.ServiceIdentifier ===
                assignment.typeOfAssignment.value
              ) {
                // keep the same type of Assignment
                // typeOfAssignment = assignment.typeOfAssignment;
                typeOfAssignment = {
                  name: allowSelect.ServiceName,
                  value: allowSelect.ServiceIdentifier,
                  isMessageService: allowSelect.IsMessageService,
                  doAllowLMANumberOnConnectedContract:
                    allowSelect.DoAllowLMANumberOnConnectedContract,
                  isPerformedRemotely: allowSelect.IsPerformedRemotely,
                  additionalServices: allowSelect.AdditionalServices,
                };
              }
            } else {
              typeOfAssignment = assignment.typeOfAssignment;
            }
          });

          const validServicesForOrders = allowSelectServices.filter(
            service =>
              service.CanBeUsedForAutoAssignOrder === false &&
              service.IsTranslation === false,
          );

          this.setState({
            assignment: {
              ...this.state.assignment,
              services: validServicesForOrders,
              typeOfAssignment,
              certificationLevels: undefined,
              doIncludeAdditionalServices: false,
              isPatientConsentGivenin: false,
              messageReceiverAdditionalService: '',
            },
          });
        } else {
          this.setState({
            assignment: {
              ...this.state.assignment,
              services: [],
              typeOfAssignment: {},
              certificationLevels: undefined,
            },
          });
        }
      };
      const params = {
        language: obj.language
          ? obj.language.value == ''
            ? languages[1].value
            : obj.language.value
          : assignment.language?.value != '' && assignment.language.value
          ? assignment.language.value
          : obj.language?.value
          ? obj.language.value
          : languages[1].value,
        startTime: startDate
          .clone()
          .hours(startTime.hours())
          .minutes(startTime.minutes()),
        endTime: endDate
          .clone()
          .hours(endTime.hours())
          .minutes(endTime.minutes()),
      };

      executeGetServices(params, callbackUpdateServices);
    } else {
      this.setState({
        assignment: {
          ...this.state.assignment,
          ...obj,
          alternativeLanguage: obj.language
            ? { name: null, value: null }
            : obj.alternativeLanguage
            ? obj.alternativeLanguage
            : this.state.assignment.alternativeLanguage,
          typeOfAssignment: obj.typeOfAssignment
            ? obj.typeOfAssignment
            : assignment.typeOfAssignment,
          ...(obj.typeOfAssignment ? this.resetMeetingRelatedData() : {}),

          selectedAssignmentTypeData: filteredItems,
          messageReceiverAdditionalService:
            obj.doIncludeAdditionalServices === true
              ? ''
              : obj.messageReceiverAdditionalService &&
                obj.messageReceiverAdditionalService !== ''
              ? obj.messageReceiverAdditionalService
              : obj.messageReceiverAdditionalService === ''
              ? ''
              : this.state.assignment.messageReceiverAdditionalService,
          isPatientConsentGivenin:
            obj.doIncludeAdditionalServices === false
              ? false
              : obj.isPatientConsentGivenin === true
              ? true
              : obj.isPatientConsentGivenin === false
              ? false
              : assignment.isPatientConsentGivenin,
          sendHoursBeforeBookingOverride:
            obj.doIncludeAdditionalServices === true
              ? 48
              : obj.sendHoursBeforeBookingOverride &&
                obj.sendHoursBeforeBookingOverride !== ''
              ? obj.sendHoursBeforeBookingOverride
              : obj.sendHoursBeforeBookingOverride === ''
              ? ''
              : this.state.assignment.sendHoursBeforeBookingOverride,
        },
      });
    }
  };

  handleContactPersonModal = (emailRequired, type = '', identifier) => {
    let { contactPerson } = this.state;
    if (identifier && identifier !== '') {
      let matchingContactPersonsList =
        this.state.assignment.contactPersons.filter(
          x => x.ContactPersonIdentifier == identifier,
        );
      matchingContactPersonsList =
        matchingContactPersonsList.length > 0
          ? matchingContactPersonsList[0]
          : {};

      contactPerson = {
        ...contactPerson,
        ...matchingContactPersonsList,
        editMode: true,
      };
    }
    this.setState({
      showContactPersonModal: true,
      contactPerson: {
        ...contactPerson,
        identifier,
        emailRequired,
        type,
      },
    });
  };

  setAssignmnetContactPersonBasedOnType = contactPerson => {
    if (contactPerson.type == 'ContactPersonUponInterpretation') {
      return {
        contactPerson: contactPerson.identifier,
        contactEmail: contactPerson.Email,
        contactDirectNumber: contactPerson.PhoneNumberDirect,
        contactNumber: contactPerson.PhoneNumberMobile,
      };
    }
    return {
      orderContactPerson: contactPerson.identifier,
      orderEmail: contactPerson.Email,
      orderContactDirectNumber: contactPerson.PhoneNumberDirect,
      orderContactNumber: contactPerson.PhoneNumberMobile,
    };
  };

  handleConfirm = () => {
    const { contactPerson } = this.state;
    const contactPersons = clone(this.state.assignment.contactPersons);
    let assignmentContact = {};
    if (contactPerson.identifier && contactPerson.identifier != '') {
      // contactPersons = contactPersons.map(x => {
      //   let editedModel = { ...x };
      //   if (x.ContactPersonIdentifier == contactPerson.identifier) {
      //     editedModel = {
      //       ...editedModel,
      //       ...contactPerson,
      //     };
      //   }
      //   return editedModel;
      // });
      assignmentContact =
        this.setAssignmnetContactPersonBasedOnType(contactPerson);
    } else {
      contactPerson.value = `newPerson-${generateUniqueId()}`;
      contactPerson.name = `${contactPerson.FirstName} ${
        contactPerson.LastName
      } ${contactPerson.Title ? `- ${contactPerson.Title}` : ''}`;
      contactPersons.push(contactPerson);
      this.props.updateContactPerson(contactPerson);
    }
    const assignmenttemp = {
      ...this.state.assignment,
      contactPersons,
      ...assignmentContact,
    };

    this.setState({
      assignment: assignmenttemp,
      contactPerson: createDefaultContactPerson(),
    });
  };

  handleChangeContactPerson = obj => {
    const { contactPerson } = this.state;
    this.setState({
      contactPerson: {
        ...contactPerson,
        ...obj,
      },
    });
  };

  handleContactPersonModalClose = () => {
    this.setState({
      showContactPersonModal: false,
      contactPerson: createDefaultContactPerson(),
    });
  };

  handleFormError = e => {
    if (e.length > 0) {
      e[0].input.scrollIntoView
        ? e[0].input.scrollIntoView({ behavior: 'smooth' })
        : e[0].input._element.scrollIntoView({ behavior: 'smooth' });
    } else {
      e.input.scrollIntoView
        ? e.input.scrollIntoView({ behavior: 'smooth' })
        : e.input._element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  isAdvancedAssigmentWizardNeeded = () => {
    const { assignment } = this.state;

    return (
      (assignment.genderValue && assignment.genderValue?.value != '') ||
      (assignment.certificationLevels &&
        assignment.certificationLevels?.value != '') ||
      (assignment.assignmentDescription &&
        assignment.assignmentDescription != '') ||
      (assignment.requestedInterpreter &&
        assignment.requestedInterpreter != '') ||
      (assignment.additionalRequirements &&
        assignment.additionalRequirements != '') ||
      (assignment.yourOrderNumber && assignment.yourOrderNumber != '')
    );
  };

  RouteLeavingModalHandler = value => {
    this.setState({
      visibleRouteLeavingModal: value,
    });
  };

  handleNewEmailChange = value => {
    this.state.assignment.newEmail = value;
  };

  handleConfirmDuplicateOrder = (assignment, orderEmail, errorMessage) => {
    assignment.DoForce = 1;
    this.setState({
      showDuplicateOrder: true,
      duplicateAssignmentDetails: { ...assignment, orderEmail },
      errorMessage: errorMessage,
    });
  };

  handleSubmitDuplicateOrder = () => {
    if (this.state.duplicateAssignmentDetails.isEdit) {
      this.props.onUpdateOrder({
        ...this.state.duplicateAssignmentDetails,
      });
    } else {
      this.props.onCreateNewOrder({
        ...this.state.duplicateAssignmentDetails,
      });
    }
    this.setState({
      showDuplicateOrder: false,
    });
  };

  handleCloseDuplicateOrder = () => {
    const { onUpdateCreatedOrderErrors } = this.props;
    this.setState({
      showDuplicateOrder: false,
      // showPreview: false
    });
    onUpdateCreatedOrderErrors();
  };

  handleDuplicateOrderCancel = () => {
    const { onUpdateCreatedOrderErrors } = this.props;
    this.setState({
      showDuplicateOrder: false,
    });
    onUpdateCreatedOrderErrors();
  };

  handleKeyDown = e => {
    if (e.key === 'Escape') {
      if (this.state.showAdvancedOrderWizard) {
        this.setState({
          showAdvancedOrderWizard: false,
        });
      } else if (this.props.showQuickOrder) {
        this.RouteLeavingModalHandler(true);
      }
    }
  };

  updateDate = () => {
    this.setState({
      isDefaultDateUpdated: true,
    });
  };

  setDefaultDate = () => {
    this.setState({
      isDefaultDateUpdated: false,
    });
  };

  onDateInvalid = () => {
    this.setState({
      isDateValid: false,
    });
  };

  toggleLanguageFocus = () => {
    const { languageFocus } = this.state;
    this.setState({
      languageFocus: !languageFocus,
    });
  };

  toggleContactPersonFocus = () => {
    const { contactPersonFocus } = this.state;
    this.setState({
      contactPersonFocus: !contactPersonFocus,
    });
  };

  toggleContactOrderPersonFocus = () => {
    const { contactOrderPersonFocus } = this.state;
    this.setState({
      contactOrderPersonFocus: !contactOrderPersonFocus,
    });
  };

  render() {
    const {
      assignment,
      showContactPersonModal,
      contactPerson,
      showPreview,
      showSuccessMessage,
      successMessageDetails,
      showAdvancedOrderWizard,
      visibleRouteLeavingModal,
      showDuplicateOrder,
      isDateValid,
      isDefaultDateUpdated,
      isTimeValid,
      languageFocus,
      contactPersonFocus,
      contactOrderPersonFocus,
    } = this.state;

    const {
      languages,
      onClose,
      profile,
      showQuickOrder,
      onSwitchToDetailOrder,
      skills,
      services,
      contactPersons,
      date,
      RouteLeavingGuardHandler,
      customerDefaultAddress,
      onHideNewBooking,
      validCustomerInvoices,
    } = this.props;

    return (
      <React.Fragment>
        {(showPreview || showSuccessMessage) && !showAdvancedOrderWizard && (
          <Modal
            className="tv-workassignment__modal"
            onCloseClicked={() => {
              if (showSuccessMessage) {
                RouteLeavingGuardHandler(false);
                onClose();
                onHideNewBooking();
                removeContextualClassForBooknow();
              } else {
                this.setState({ showPreview: false });
              }
            }}
            isVisible={showPreview || showSuccessMessage}
            headerClassName={`tv-workassignment__modal-header ${
              isIE ? 'ie-fix-width' : ''
            }`}
            headerText={Translate({
              content: showSuccessMessage
                ? 'singleAssignment.successModalHeader'
                : 'singleAssignment.assignmentDetail',
            })}
            ieOptionClassName={isIE ? 'ie-fix-height' : ''}
          >
            {showPreview && (
              <PreviewAssignment
                onClose={() => {
                  if (this.isAdvancedAssigmentWizardNeeded()) {
                    this.setState({
                      showPreview: false,
                      showAdvancedOrderWizard: true,
                    });
                  } else {
                    this.setState({ showPreview: false });
                  }
                }}
                isBasic
                showPreview={showPreview}
                handleConfirmDuplicateOrder={this.handleConfirmDuplicateOrder}
                customerDefaultAddress={customerDefaultAddress}
              />
            )}
            {showDuplicateOrder && (
              <ConfirmDuplicateOrder
                onSubmit={this.handleSubmitDuplicateOrder}
                onClose={this.handleCloseDuplicateOrder}
                onCloseIcon={this.handleDuplicateOrderCancel}
                errorMessage={this.state.errorMessage}
              />
            )}
            {showSuccessMessage && (
              <SuccessMessage
                onClose={() => {
                  this.setState({
                    showPreview: false,
                    showSuccessMessage: false,
                  });
                  RouteLeavingGuardHandler(false);
                  onClose();
                  onHideNewBooking();
                }}
                mode={assignment.isEdit ? 'edit' : 'assignment'}
                onContinueBooking={services => {
                  this.setState({
                    showPreview: false,
                    showSuccessMessage: false,
                    isDefaultDateUpdated: false,
                    assignment: {
                      ...createDefaultAssignment(
                        SINGLE_FORM,
                        skills,
                        this.state.assignment.services || [],
                        clone(contactPersons),
                        profile,
                        date,
                      ),
                    },
                  });
                }}
                visible={showSuccessMessage}
                assignmentDetails={successMessageDetails}
              />
            )}
          </Modal>
        )}

        {showAdvancedOrderWizard && (
          <AdvancedAssigmentWizard
            onSwitchToDetailOrder={onSwitchToDetailOrder}
            assignment={assignment}
            handleValidatorListener={this.handleValidatorListener}
            handleChange={this.handleChange}
            handleModal={this.handleContactPersonModal}
            languages={languages}
            profile={profile}
            onCloseAdvancedModal={() => {
              this.setState({ showAdvancedOrderWizard: false });
            }}
            onCreateNewDefaultForm={services => {
              this.setState({
                showPreview: false,
                showSuccessMessage: false,
                assignment: {
                  ...createDefaultAssignment(
                    SINGLE_FORM,
                    skills,
                    this.state.assignment.services || [],
                    clone(contactPersons),
                    profile,
                    date,
                  ),
                },
              });
            }}
            onCloseBooking={() => {
              onClose();
              RouteLeavingGuardHandler(false);
            }}
            visible={showAdvancedOrderWizard}
            isAlternativeAddressAdded={this.state.isAlternativeAddressAdded}
            addAlternativeAddress={this.addAlternativeAddress}
            handleNewEmailChange={this.handleNewEmailChange}
            customerDefaultAddress={customerDefaultAddress}
            isDefaultDateUpdated={isDefaultDateUpdated}
            updateDate={this.updateDate}
            setDefaultDate={this.setDefaultDate}
            isDateValid={isDateValid}
            onDateInvalid={this.onDateInvalid}
          />
        )}

        {showContactPersonModal && (
          <ContactPersonEdit
            {...contactPerson}
            showModal={showContactPersonModal}
            inOrderProcess
            onHandleModal={this.handleContactPersonModalClose}
          />
        )}

        <RouteLeavingModal
          isVisible={visibleRouteLeavingModal}
          onCloseClicked={() => {
            this.RouteLeavingModalHandler(false);
          }}
          onOverlayClick={() => {
            this.RouteLeavingModalHandler(false);
          }}
          onConfirmClick={() => {
            RouteLeavingGuardHandler(false);
            onClose();
            onHideNewBooking();
          }}
        />

        <LeftDrawerSlide
          isVisible={showQuickOrder}
          hideClose
          hideExternalScroll
          onClose={onClose}
          className="tk-quick-order__drawer"
        >
          <Dialog
            className="tk-assignment-creation__container"
            isVisible
            onClose={() => {
              this.RouteLeavingModalHandler(true);
            }}
            ariaLive="off"
            contactPersonFocus={contactPersonFocus}
            contactOrderPersonFocus={contactOrderPersonFocus}
            languageFocus={languageFocus}
            paused
          >
            <div className="tk-assignment-list-box_header">
              {assignment.isEdit ? (
                `${Translate({ content: 'dashboard.editAssignment' })} ${
                  assignment?.OrderNumber && ` ${assignment?.OrderNumber}`
                }`
              ) : (
                <Translate content="dashboard.history" />
              )}
            </div>
            <div className="tk-assignment-list-box_body">
              <ValidatorForm
                ref={this.quickOrderRef}
                onSubmit={this.handleSubmit}
                onError={this.handleFormError}
              >
                <QuickOrderForm
                  {...assignment}
                  validatorListener={this.handleValidatorListener}
                  onChange={this.handleChange}
                  onHandleModal={this.handleContactPersonModal}
                  languages={languages}
                  alternativeLanguages={this.state.alternativeLanguages}
                  openAdvanceView={() => {
                    this.setState({ isAddDemands: true });
                    this.quickOrderRef.current.submit();
                  }}
                  onClose={() => {
                    this.RouteLeavingModalHandler(true);
                  }}
                  ContactPersonOrdererRequiresEmail={
                    profile.ContactPersonOrdererRequiresEmail
                  }
                  ContactPersonUponInterpretationRequiresEmail={
                    profile.ContactPersonUponInterpretationRequiresEmail
                  }
                  createdFrom="singleAssignment"
                  onSwitchToMultipleOrder={this.handleSwitchToMultipleOrder}
                  isSingle
                  isAltLanguageAdded={this.state.isAltLanguageAdded}
                  addAltLanguage={this.addAltLanguage}
                  ValidatorForm={ValidatorForm}
                  confirmationEmail={this.state.confirmationEmail}
                  isAlternativeAddressAdded={
                    this.state.isAlternativeAddressAdded
                  }
                  addAlternativeAddress={this.addAlternativeAddress}
                  handleNewEmailChange={this.handleNewEmailChange}
                  customerDefaultAddress={customerDefaultAddress}
                  isDateValid={isDateValid}
                  isDefaultDateUpdated={isDefaultDateUpdated}
                  updateDate={this.updateDate}
                  isTimeValid={isTimeValid}
                  toggleContactPersonFocus={this.toggleContactPersonFocus}
                  toggleContactOrderPersonFocus={
                    this.toggleContactOrderPersonFocus
                  }
                  toggleLanguageFocus={this.toggleLanguageFocus}
                  checkIsPatientConsentGivenin={
                    this.state.checkIsPatientConsentGivenin
                  }
                  validCustomerInvoices={validCustomerInvoices}
                />
              </ValidatorForm>
            </div>
          </Dialog>
        </LeftDrawerSlide>
      </React.Fragment>
    );
  }
}

QuickOrder.propTypes = propTypes;
QuickOrder.defaultProps = defaultProps;

const mapStateToProps = state => {
  const assignment = state.workAssignment.assignmentQuickOrder;
  const { assignmentTemp } = state.workAssignment;
  const contactPersons = state.user.contactPersons || [];
  const newContactPersons = state.workAssignment.contactPersons || [];
  const profile = state.user.profile || [];
  const skills = state.skill.skills || [];
  const substituteSkills = state.skill.substituteSkills || [];
  const languages = skills.map((item, i) => ({
    value: item.SkillIdentifier,
    name: item.SkillName,
  }));
  const alternativeLanguages = {};
  const skillIdentifiers = Object.keys(substituteSkills);

  skillIdentifiers.forEach(skill => {
    const availableSubSkills = substituteSkills[skill].map(subSkill => ({
      value: subSkill.SkillIdentifier,
      name: subSkill.SkillName,
    }));
    alternativeLanguages[skill] = availableSubSkills;
  });
  const customerDefaultAddress = state.user.profile.customerAddress;
  const validCustomerInvoices =
    profile.ValidCustomerInvoice?.map(item => ({
      name: item.CustomerInvoiceName,
      value: item.CustomerInvoiceIdentifier,
    })) || [];

  return {
    assignment,
    assignmentTemp,
    skills,
    contactPersons,
    newContactPersons,
    profile,
    languages,
    alternativeLanguages,
    customerDefaultAddress,
    validCustomerInvoices,
  };
};

const mapDispatchToProps = dispatch => ({
  executeGetContactPersons: () => {
    dispatch(fetchContactPersons());
  },
  executeGetServices: (params, callback) => {
    dispatch(fetchServices(params, callback));
  },
  onSwitchToDetailOrder: assignment => {
    dispatch(onUpdateNewAssignmentsTemp(assignment));
  },
  updateContactPerson: contactPerson => {
    dispatch(UpdateContactPersons(contactPerson));
  },
  onCreateNewOrder: assignment => {
    dispatch(createNewOrder(assignment));
  },
  onUpdateOrder: assignment => {
    dispatch(updateOrder(assignment));
  },
  onUpdateCreatedOrderErrors: () => {
    dispatch(setEmptyCreateNewOrderErrors());
  },
  onHideNewBooking: () => {
    dispatch(hideNewBooking());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickOrder);
