import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import StarGroup from '../StarGroup';
import {
  Translate,
  Modal,
  Row,
  Col,
  DropdownList,
  TextField,
  TFVButton,
} from '../../../Share/components';
import CreateDeviation from '../CreateDeviation';
import { isIE } from '../../../Share/utils';
import { PAGE_TYPES } from '../../../Share/constants';
import './style.scss';
import { getAttachment, setOrderRating, createDeviation } from '../../actions';
import SuccessMessage from '../NewAssignment/SuccessMessage';

const propTypes = {
  assignmentDetail: PropTypes.shape({}),
  localWorkAssignment: PropTypes.shape({}),
  onOrderRated: PropTypes.func,
  getRateReasons: PropTypes.func,
  from: PropTypes.string,
  ratingReasons: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  assignmentDetail: {},
  onOrderRated: () => {},
  getRateReasons: () => {},
  localWorkAssignment: {},
  from: '',
};

class TimeSheet extends Component {
  constructor(props) {
    super(props);
    this.originalTitle = document.title;
    this.state = {
      rating: 0,
      ratingReason: { name: null, value: null },
      comment: '',
      isDeviation: false,
      showDeviation: false,
      showSuccessMessage: false,
      feedback: [],
      charCount: 0,
    };
  }
  async componentDidMount() {
    const { assignmentDetail } = this.props;
    document.title =
      'Ge feedback ' + assignmentDetail.OrderNumber + ' - TFV Kundportal';
  }
  async componentWillUnmount() {
    document.title = this.originalTitle;
  }

  getValueFromList = (filterText, list) => {
    if (filterText === null || filterText === '') return null;
    const filteredItems = list.filter(x => x.value === filterText);
    return filteredItems ? filteredItems[0] : null;
  };

  deviationFeedBack = DeviationNumber => {
    this.setState({
      showSuccessMessage: true,
      deviationNumber: DeviationNumber,
    });
  };

  rateFeedBack = feedback => {
    const { from } = this.props;
    const { isDeviation } = this.state;
    if (feedback.IsSuccess && isDeviation) {
      this.setState({
        showDeviation: true,
      });
    } else if (feedback.IsSuccess && !isDeviation) {
      if (from === PAGE_TYPES.DASHBOARD) {
        window.location.href = '/';
      } else if (from === PAGE_TYPES.ASSIGNMENT) {
        window.location.href = '/my-assignment';
      }
    }
    return null;
  };

  navigateOnDeviationSuccess = () => {
    const { from } = this.props;
    if (from === PAGE_TYPES.DASHBOARD) {
      window.location.href = '/';
    } else if (from === PAGE_TYPES.ASSIGNMENT) {
      window.location.href = '/my-assignment';
    }
    return null;
  };

  handleSubmit = () => {
    const { rating, ratingReason, comment } = this.state;

    const { assignmentDetail, onOrderRated } = this.props;

    // if (rating !== 0) {
    onOrderRated(
      assignmentDetail?.OrderIdentifier,
      parseInt(rating, 10),
      comment,
      ratingReason.value == '' ? null : ratingReason.value,
      this.rateFeedBack,
      assignmentDetail.ContactPerson.ContactPersonIdentifier,
    );
    // }
  };

  setShowDeviationModal = status => {
    this.setState({
      showDeviation: status,
    });
  };

  handleRate = event => {
    const {
      target: { type, name, value },
    } = event;
    if (name == 'rating') {
      this.setState({
        ratingReason: { name: 'Välj', value: '' },
      });
    }
    if (type === 'checkbox') {
      this.setState(prevState => ({
        isDeviation: !prevState.isDeviation,
      }));
    } else {
      if (name == 'comment') {
        this.setState({
          charCount: value.length,
        });
      }
      this.setState(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  RouteLeavingModalHandler = value => {
    this.setState({
      visibleRouteLeavingModal: value,
    });
  };

  createDeviation = () => {
    const { rating, ratingReason, comment } = this.state;
    const { assignmentDetail, onOrderRated } = this.props;

    this.setState({ isDeviation: true });
    // if (rating !== 0) {
    onOrderRated(
      assignmentDetail?.OrderIdentifier,
      parseInt(rating, 10),
      comment,
      ratingReason.value == '' ? null : ratingReason.value,
      this.rateFeedBack,
    );
    // }
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { ratingReasons, showModal, onHandleModal, assignmentDetail } =
      this.props;
    const {
      rating,
      ratingReason,
      comment,
      showSuccessMessage,
      deviationNumber,
      showDeviation,
      charCount,
    } = this.state;

    let reasons = [];
    if (rating) {
      if (ratingReasons) {
        reasons = ratingReasons.filter(
          rate =>
            rate.LowestRatingFromCustomer <= rating &&
            rate.HighestRatingFromCustomer >= rating,
        );
      }
    }

    return (
      <div>
        <Modal
          isVisible={showModal}
          className="tv-workassignment__modal feedBack__modal"
          onCloseClicked={() => {
            onHandleModal();
          }}
          onOverlayClick={() => {
            onHandleModal();
          }}
          headerClassName={`tv-workassignment__modal-header ${
            isIE ? 'ie-fix-width' : ''
          }`}
          headerText={
            showDeviation || showSuccessMessage
              ? Translate({
                  content: 'report.sendDeviation',
                })
              : Translate({
                  content: 'calendar.feedBackModalTitle',
                })
          }
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        >
          {showSuccessMessage && (
            <SuccessMessage
              mode="deviation"
              onClose={() => {
                this.setState({
                  showSuccessMessage: false,
                });
                onHandleModal();
                this.navigateOnDeviationSuccess();
              }}
              visible={showSuccessMessage}
              deviationNumber={deviationNumber}
            />
          )}

          {showDeviation && (
            <CreateDeviation
              assignment={assignmentDetail}
              isGeneral={false}
              onCloseClicked={() => {
                this.setShowDeviationModal(false);
              }}
              onDeviationSuccess={createdDeviationNumber => {
                this.setShowDeviationModal(false);
                this.deviationFeedBack(createdDeviationNumber);
              }}
            />
          )}

          {!showSuccessMessage && !showDeviation && (
            <ValidatorForm
              ref="form"
              className="tv-reportquality-form "
              onSubmit={this.handleSubmit}
            >
              <div aria-live="polite">
                <Row>
                  <div className="feedBack">
                    <Row>
                      <Col md={12} sm={12}>
                        <div
                          className="feedBack-header"
                          style={{ marginTop: '35px' }}
                        >
                          {Translate({
                            content: 'calendar.feedBackTitle',
                          })}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={12}>
                        <div className="feedBack-content mt-2">
                          Hur gick tolkningen?
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={12}>
                        <div className="feedBack-content">
                          1-2 stjärnor utreds av TFV, beskriv i fritextrutan vad
                          som har hänt.
                        </div>
                        <div className="feedBack-content">
                          3-5 stjärnor är positivt.
                        </div>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col md={12} sm={12}>
                        <div className="star-group">
                          <StarGroup
                            className="star-group-container"
                            size={40}
                            value={rating}
                            activeColor="#FF9B4E"
                            handleChange={e =>
                              this.handleRate({
                                target: {
                                  name: 'rating',
                                  value: e,
                                },
                              })
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    {rating > 0 && (
                      <Row>
                        <Col md={6} sm={12}>
                          <span className="rating-label">
                            <Translate content="feedback.reason" />
                          </span>
                          <div className="field-wrapper">
                            <DropdownList
                              name="ratingReason"
                              className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                              options={reasons}
                              value={this.getValueFromList(
                                ratingReason.value,
                                reasons,
                              )}
                              onChange={this.handleChange}
                              // validators={['required']}
                              errorMessages={[
                                Translate({
                                  content: 'validator.isRequired',
                                  params: {
                                    name: Translate({
                                      content: 'feedback.reason',
                                    }),
                                  },
                                }),
                              ]}
                              ariaLabel={Translate({
                                content: 'feedback.reason',
                              })}
                            />
                          </div>
                        </Col>
                        <Col md={6} sm={12}>
                          <label
                            className="rating-label m-0"
                            htmlFor="contactPerson"
                          >
                            <Translate content="feedback.contactPerson" />
                          </label>
                          <div className="field-wrapper">
                            <TextField
                              aria-label={Translate({
                                content: 'feedback.contactPerson',
                              })}
                              className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                              value={
                                assignmentDetail.ContactPerson.ContactPersonName
                              }
                              readOnly={true}
                              name="contactPerson"
                              id="contactPerson"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {rating <= 2 && rating > 0 && (
                      <div className="form-wrapper mt-2">
                        <Row>
                          <Col md={12} sm={12}>
                            <label
                              className="rating-label m-0"
                              htmlFor="comment"
                            >
                              <Translate content="reason.writeAComment" />
                            </label>
                            <div className="field-wrapper">
                              <TextField
                                aria-label={Translate({
                                  content: 'reason.writeAComment',
                                })}
                                multiline
                                className="ellipsis pl-3 py-1 tv-single-assign-form__control mb-0"
                                placeholder={Translate({
                                  content: 'timesheet.descripeYourRating',
                                })}
                                cols="10"
                                name="comment"
                                id="comment"
                                rows="3"
                                onChange={this.handleRate}
                                value={comment}
                                maxLength="900"
                              />
                            </div>
                            {charCount > 0 && (
                              <div
                                style={{ color: '#74737b' }}
                              >{`${charCount} / 900`}</div>
                            )}
                          </Col>
                        </Row>
                        {/* {!assignmentDetail.HasDeviation && (
                          <Row>
                            <Col md={6}>
                              <div
                                className="feedback-create-deviation"
                                onClick={this.createDeviation}
                              >
                                {Translate({
                                  content: 'reportQuality.isADeviation',
                                })}
                              </div>
                            </Col>
                          </Row>
                        )} */}
                      </div>
                    )}
                    {rating > 2 && (
                      <Row className="mt-2">
                        <Col md={12} sm={12}>
                          <label className="rating-label m-0" htmlFor="comment">
                            <Translate content="reason.writeAComment" />
                          </label>
                          <div className="field-wrapper">
                            <TextField
                              aria-label="comment"
                              multiline
                              className="ellipsis pl-3 py-1 tv-single-assign-form__control label-height mb-0"
                              placeholder={Translate({
                                content: 'timesheet.descripeYourRating',
                              })}
                              cols="10"
                              name="comment"
                              id="comment"
                              rows="3"
                              onChange={this.handleRate}
                              value={comment}
                              maxLength="900"
                            />
                          </div>
                          {charCount > 0 && (
                            <div
                              style={{ color: '#74737b' }}
                            >{`${charCount} / 900`}</div>
                          )}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <button
                          onClick={() => {
                            onHandleModal();
                          }}
                          className="timesheet-button_cancel"
                        >
                          <Translate content="timesheet.cancel" />
                        </button>
                      </Col>
                      <Col>
                        <TFVButton
                          type="secondary"
                          buttonType="submit"
                          className="timesheet-button_submit"
                          text={Translate({
                            content: 'reason.sendFeedBack',
                          })}
                        />
                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>
            </ValidatorForm>
          )}
        </Modal>
      </div>
    );
  }
}

TimeSheet.propTypes = propTypes;
TimeSheet.defaultProps = defaultProps;

const mapStateToProps = state => ({
  // assignmentDetail: state.workAssignment.assignmentDetail,
  localWorkAssignment: state.localWorkAssignment,
  token: state.authentication.token,
  ratingReasons: state.workAssignment.ratingReasons,
});

const mapDispatchToProps = dispatch => ({
  getAttachment: e => {
    dispatch(getAttachment(e));
  },

  onOrderRated: (
    orderIdentifier,
    rate,
    comment,
    reason,
    callback,
    contactPerson,
    createDeviation = true,
  ) => {
    dispatch(
      setOrderRating(
        orderIdentifier,
        rate,
        comment,
        reason,
        callback,
        contactPerson,
        createDeviation,
      ),
    );
  },

  onCreateDeviation: (data, callback) => {
    dispatch(createDeviation(data, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheet);
