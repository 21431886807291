import React, { useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { Translate, TFVButton } from '../../../Share/components';
import {
  getDateFormated,
  removeContextualClassForBooknow,
} from '../../../Share/utils';
// eslint-disable-next-line react/prop-types
const DataItem = ({ label, value }) => (
  <div className="tv-booknow-complete__data-item">
    <div className="tv-booknow-complete__data-item-label">{label}</div>
    <div className="tv-booknow-complete__data-item-value">{value}</div>
  </div>
);
const DataItemLink = ({ label, value }) => (
  <div className="tv-booknow-complete__data-item">
    <div className="tv-booknow-complete__data-item-label">{label}</div>
    <div className="tv-booknow-complete__data-item-value">
      <a href={'tel:' + value}>{value}</a>
    </div>
  </div>
);

function OrderComplete({
  orderNumber,
  dateRange: { TimeFrom, TimeTo },
  language: { name },
  customerName,
  onClose,
  onSubmit,
  bookedInterpreterName,
  bookedInterpreterNumber,
  showInfo,
}) {
  const componentRef = useRef(null);
  const dateTimeFrom = moment(TimeFrom);
  const dateTimeTo = moment(TimeTo);

  const endTime = getDateFormated(dateTimeTo, 'HH:mm, DD MMMM');

  return (
    <>
      <div className="tv-booknow-complete__container" ref={componentRef}>
        <div className="tv-booknow-complete__heading">
          {Translate({ content: 'bookNow.yourBookingIsMade' })}
        </div>
        <div className="tv-booknow-complete__reference-number">
          {Translate({
            content: 'bookNow.yourOrderReferenceIs',
            params: {
              orderNumber,
            },
          })}
        </div>
        <hr />
        <div>
          <div className="tv-booknow-complete__data-heading">
            Bokningsinformation
          </div>
          <DataItem
            label="Datum"
            value={`${dateTimeFrom.format('HH:mm')} - ${endTime}`}
          />
          <DataItem label="Språk" value={name} />
          <DataItem label="Typ av tolkning" value="Telefon" />
          <DataItem label="Enhet" value={customerName} />
          <hr />
          <div className="tv-booknow-complete__data-heading">Tillsatt tolk</div>
          <DataItem label="Namn" value={bookedInterpreterName} />
          <DataItemLink label="Telefonnummer" value={bookedInterpreterNumber} />
          {showInfo && (
            <DataItem label="Du som tolkanvändare ringer tolken vid bokningens starttid." />
          )}
          <hr />
        </div>
      </div>
      <div className="tv-booknow-complete__data-item__sent-to">
        <ReactToPrint
          trigger={() => (
            <div className="td-block ml-auto tv-workassignment__detail-links-item">
              {Translate({
                content: 'reportQuality.printButton',
              })}
            </div>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="tv-booknow__form__buttons tv-booknow__form__buttons--success tv-booknow-complete__buttons  justify-content-between tv-display-flex">
          {/* <TFVButton
            type="line-primary"
            text={Translate({
              content: 'bookNow.keepBooking',
            })}
            onClick={() => onClose()}
          /> */}
          <TFVButton
            type="line-primary"
            text={Translate({
              content: 'bookNow.toCalendar',
            })}
            onClick={() => {
              onSubmit();
              removeContextualClassForBooknow();
            }}
          />
        </div>
      </div>
    </>
  );
}

OrderComplete.propTypes = {
  orderNumber: PropTypes.string,
  bookedInterpreterName: PropTypes.string,
  bookedInterpreterNumber: PropTypes.string,
  dateRange: PropTypes.shape({
    TimeFrom: PropTypes.string,
    TimeTo: PropTypes.string,
  }),
  language: PropTypes.shape({
    name: PropTypes.string,
  }),
  customerName: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  showInfo: PropTypes.bool,
};

OrderComplete.defaultProps = {
  orderNumber: '',
  bookedInterpreterName: '',
  bookedInterpreterNumber: '',
  language: {
    name: '',
  },
  dateRange: {
    TimeFrom: '00:00',
    TimeTo: '00:00',
  },
  customerName: '',
  onClose: () => {},
  onSubmit: () => {},
  showInfo: false,
};

export default OrderComplete;
