import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IfComponent from '../IfComponent';

const propTypes = {
  isVisible: PropTypes.bool,
};

const defaultProps = {
  isVisible: false,
};

const LoadingOverlay = ({ isVisible }) => {
  useEffect(() => {
    if (isVisible) {
      const preventKeyboardEvents = e => {
        e.preventDefault();
        e.stopPropagation();
      };

      document.addEventListener('keydown', preventKeyboardEvents, true);
      document.addEventListener('keypress', preventKeyboardEvents, true);
      document.addEventListener('keyup', preventKeyboardEvents, true);

      return () => {
        document.removeEventListener('keydown', preventKeyboardEvents, true);
        document.removeEventListener('keypress', preventKeyboardEvents, true);
        document.removeEventListener('keyup', preventKeyboardEvents, true);
      };
    }
  }, [isVisible]);

  return (
    <IfComponent
      condition={isVisible}
      whenTrue={
        <div className="tv-loading__wrapper">
          <div className="tv-loading__content">
            <div className="tv-loading__spinner" />
          </div>
        </div>
      }
    />
  );
};

LoadingOverlay.propTypes = propTypes;
LoadingOverlay.defaultProps = defaultProps;

const mapStateToProps = state => ({
  isVisible: state.app.showLoadingOverlay,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingOverlay);
