import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { clone } from 'lodash/core';
import {
  currentDate,
  getXTimefromSpecificDate,
} from '../../../Share/utils/dateUtil';
import history from '../../../Share/utils/history';
import {
  Translate,
  DropdownList,
  TFVTimePickerV2,
  TFVDateRangePicker,
  TFVButton,
  TextField,
  InputText,
  Checkbox,
  HelpContext,
} from '../../../Share/components';
import { cloneDeep, isEmpty } from 'lodash-es';
import {
  isContactPersonValid,
  messageFieldValidate,
} from '../../../Share/utils';
import TagInput from '../../../Share/components/TagInput';
import { connect } from 'react-redux';
import { filterBy } from '@progress/kendo-data-query';

const propTypes = {
  onChange: PropTypes.func,
  onSwitchToMultipleOrder: PropTypes.func,
  isSameAbove: PropTypes.bool,
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  dateRange: PropTypes.shape({}),
  contactPerson: PropTypes.string,
  profile: PropTypes.shape({}),
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  isSingle: PropTypes.bool,
  onClose: PropTypes.func,
  openAdvanceView: PropTypes.func,
  // RouteLeavingGuardHandler: PropTypes.func,
  ValidatorForm: PropTypes.func,
  handleNewEmailChange: PropTypes.func,
  DoShowLMANumber: PropTypes.bool,
  CustomerEmail: PropTypes.string,
  customerDefaultAddress: PropTypes.shape({}),
  isDefaultDateUpdated: PropTypes.bool,
  updateDate: PropTypes.func,
  customerName: PropTypes.string,
};

const defaultProps = {
  onChange: () => {},
  onClose: () => {},
  onSwitchToMultipleOrder: () => {},
  openAdvanceView: () => {},
  isSameAbove: false,
  contactPersons: [],
  services: [],
  dateRange: {},
  contactPerson: '',
  profile: {},
  languages: [],
  isSingle: false,
  // RouteLeavingGuardHandler: () => {},
  ValidatorForm: () => {},
  handleNewEmailChange: () => {},
  DoShowLMANumber: false,
  CustomerEmail: '',
  customerDefaultAddress: {},
  isDefaultDateUpdated: false,
  updateDate: () => {},
  customerName: '',
};

class QuickOrderForm extends Component {
  constructor(props) {
    super(props);
    const isTodayIsNotSelected = this.validationDatePicker();
    this.state = {
      isSameAbove: props.isSameAbove,
      showModal: false,
      showCerfiticate: props.showCerfiticate,
      isTodayIsNotSelected,
      isDirty: true,
      errorMessage: '',
      tagInputText: '',
      isKeyReleased: false,
      languagesList: props.languages,
      isContactPersonDropdownExpanded: false,
      isLastContactPerson: false,
      isContactPersonFooterFocused: false,
      isContactOrderPersonDropdownExpanded: false,
      isLastContactOrderPerson: false,
      isContactOrderPersonFooterFocused: false,
      showLMANumber: false,
    };
    this.scrollContainer = React.createRef();
  }
  componentDidMount() {
    const { ValidatorForm, DoShowLMANumber, typeOfAssignment } = this.props;
    if (typeOfAssignment) {
      if (
        DoShowLMANumber &&
        typeOfAssignment.doAllowLMANumberOnConnectedContract
      ) {
        this.setState({ showLMANumber: true });
      }
    }

    ValidatorForm.addValidationRule('messageFieldValid', value => {
      if (value && value === '') {
        return true;
      }
      const { isValid, errorMessage } = messageFieldValidate(value);
      this.setState({ errorMessage });
      return isValid;
    });
    ValidatorForm.addValidationRule('validEmail', value => {
      if ((value && value == '') || !value) return true;
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (value.match(mailformat)) {
        return true;
      }
      return false;
    });
  }
  validationDatePicker = () => {
    let isTodayIsNotSelected = true;
    const todayDate = moment(new Date()).format('DD/MM/YYYY');
    // const selectedDate =
    //   this.props.dateRange.ranges.startDate.format('DD/MM/YYYY');
    // if (selectedDate === todayDate) isTodayIsNotSelected = false;
    return isTodayIsNotSelected;
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;
    this.handleAssignmentChange(event);
    this.props.onChange({ [name]: value });
  };

  handleEmailChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState({ tagInputText: value });
  };

  checkEmailFormat = email => {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    }
    return false;
  };

  handleKeyDown = e => {
    let { customerOrderEmailAddress } = this.props;
    const { key } = e;
    const trimmedInput = this.state.tagInputText.trim();

    let list = [];
    if (
      key === ';' &&
      trimmedInput.length &&
      this.checkEmailFormat(trimmedInput) &&
      !(customerOrderEmailAddress || []).includes(trimmedInput)
    ) {
      e.preventDefault();
      list = [...customerOrderEmailAddress, trimmedInput];
      this.setState({ tagInputText: '' });
      this.props.onChange({ customerOrderEmailAddress: list });
    }
    if (
      key === 'Backspace' &&
      !this.state.tagInputText.length &&
      customerOrderEmailAddress.length &&
      this.state.isKeyReleased
    ) {
      const tagsCopy = [...customerOrderEmailAddress];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      list = [...tagsCopy];
      this.setState({ tagInputText: poppedTag });
      this.props.onChange({ customerOrderEmailAddress: list });
    }
    if (key === 'Enter') {
      e.preventDefault();
      if (
        trimmedInput.length &&
        this.checkEmailFormat(trimmedInput) &&
        !(customerOrderEmailAddress || []).includes(trimmedInput)
      ) {
        list = [...customerOrderEmailAddress, trimmedInput];
        this.setState({ tagInputText: '' });
        this.props.onChange({ customerOrderEmailAddress: list });
      }
    }

    this.setState({ isKeyReleased: false });
  };

  handleKeyUp = () => {
    this.setState({ isKeyReleased: true });
  };

  handleDeleteTagInput = index => {
    const list = this.props.customerOrderEmailAddress.filter(
      (tag, i) => i !== index,
    );
    // this.props.customerOrderEmailAddress = [...list];
    this.props.onChange({ customerOrderEmailAddress: list });
  };

  handleCheckEmail = () => {
    const { tagInputText } = this.state;
    let { customerOrderEmailAddress } = this.props;
    if (!isEmpty(tagInputText)) {
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (
        tagInputText.match(mailformat) &&
        !(customerOrderEmailAddress || []).includes(tagInputText)
      ) {
        let list = [...customerOrderEmailAddress, tagInputText];
        this.setState({ tagInputText: '' });
        this.props.onChange({ customerOrderEmailAddress: list });
      }
      if ((customerOrderEmailAddress || []).includes(tagInputText)) {
        this.setState({ tagInputText: '' });
      }
    }
  };

  handleSessionChange = (e, key) => {
    const dateRange = clone(this.props.dateRange); // use clone() to fix object reference issue
    dateRange[key] = e;
    const baseStartTime = dateRange ? dateRange.startTime : null;
    const baseEndTime = dateRange ? dateRange.endTime : null;

    if (key !== 'startTime' && key !== 'endTime') {
      const selectedDate = e.startDate.format('DD/MM/YYYY');
      const todayDate = moment(new Date()).format('DD/MM/YYYY');
      if (selectedDate === todayDate) {
        this.setState({ isTodayIsNotSelected: false });
        e.startDate.add('1', 'days');
        e.endDate.add('1', 'days');
      } else {
        this.setState({ isTodayIsNotSelected: true });
      }
    }

    // update start/end time values of this session
    if (key === 'startTime') {
      const { startTime } = clone(dateRange); // use clone() to fix object reference issue
      const { endTime } = dateRange;

      dateRange.endTime = getXTimefromSpecificDate(
        startTime,
        dateRange.durationDifference,
        'minutes',
      );
    } else if (key === 'endTime') {
      const { endTime } = clone(dateRange); // use clone() to fix object reference issue
      const { startTime } = dateRange;

      const durationDiffInMinutes = moment
        .duration(endTime - startTime)
        .asMinutes();
      dateRange.durationDifference = durationDiffInMinutes;
    }

    const isChangeSerialDuration = true;
    this.props.onChange({ dateRange, isChangeSerialDuration });
  };

  handleSessionPreferredDateChange = e => {
    this.props.onChange({ preferredTime: e });
  };

  handleSessionDateChange = (e, element) => {
    const { dateRange, updateDate } = this.props;
    const { key } = dateRange.ranges;
    element.onToggleMenu();
    if (key && e[key]) {
      const { startDate, endDate } = e[key];
      if (startDate && endDate) {
        this.handleSessionChange(
          {
            startDate: moment(startDate),
            endDate: moment(endDate),
            key,
          },
          'ranges',
        );
      }
    }
    updateDate();
  };

  handleAssignmentChange = e => {
    const {
      target: { value },
    } = e;

    const { services } = this.props;
    const selectedService = services.filter(
      item => item.ServiceIdentifier === value.value,
    );
    if (selectedService.length > 0) {
      const availableCompetences = (
        selectedService[0].AvailableCompetences || []
      ).map((item, i) => ({
        name: item.CompetenceLevelName,
        value: item.CompetenceLevelIdentifier,
      }));
      this.setState({ ...this.state, availableCompetences });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      isLastContactPerson,
      isContactPersonDropdownExpanded,
      isLastContactOrderPerson,
      isContactOrderPersonDropdownExpanded,
    } = this.state;
    if (
      isLastContactPerson === true &&
      isContactPersonDropdownExpanded === true
    ) {
      document.addEventListener('keydown', this.handleContactPersonKeyDown);
    }
    if (
      prevState.isContactPersonFooterFocused !==
        this.state.isContactPersonFooterFocused &&
      this.state.isContactPersonFooterFocused
    ) {
      document.addEventListener('keydown', this.handleContactPersonKeyDownEnd);
    }
    if (
      isLastContactOrderPerson === true &&
      isContactOrderPersonDropdownExpanded === true
    ) {
      document.addEventListener(
        'keydown',
        this.handleOrderContactPersonKeyDown,
      );
    }
    if (
      prevState.isContactOrderPersonFooterFocused !==
        this.state.isContactOrderPersonFooterFocused &&
      this.state.isContactOrderPersonFooterFocused
    ) {
      document.addEventListener(
        'keydown',
        this.handleOrderContactPersonKeyDownEnd,
      );
    }

    const { DoShowLMANumber, typeOfAssignment } = this.props;
    if (prevProps.typeOfAssignment !== typeOfAssignment) {
      if (typeOfAssignment) {
        if (
          DoShowLMANumber &&
          typeOfAssignment.doAllowLMANumberOnConnectedContract
        ) {
          this.setState({ showLMANumber: true });
        } else {
          this.props.onChange({ lmaNumber: '' });
          this.setState({ showLMANumber: false });
        }
      }
    }
  }

  changeContactValue = event => {
    const {
      contactPersons,
      onHandleModal,
      profile,
      handleNewEmailChange,
      CustomerEmail,
    } = this.props;
    const { isSameAbove } = this.state;
    if (event.target.value == 'ADD_CONTACTPERSON') {
      onHandleModal();
      return;
    }
    const selectedContactPerson = event.target.value;
    const contactPersonData = {
      contactPerson: selectedContactPerson ? selectedContactPerson.value : '',
      contactNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberMobile
        : '',
      contactDirectNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberDirect
        : '',
      contactEmail: selectedContactPerson ? selectedContactPerson.Email : '',
    };
    if (profile.ContactPersonUponInterpretationRequiresEmail) {
      handleNewEmailChange(selectedContactPerson.Email);
    }
    // else {
    //   handleNewEmailChange(CustomerEmail);
    // }
    this.props.onChange(contactPersonData);
    if (isSameAbove && event.target.value !== 'none') {
      const e = {
        target: {
          value: selectedContactPerson.ContactPersonIdentifier,
        },
      };
      this.changeContactOrderValue(e);
    }

    if (isSameAbove && event.target.value === 'none') {
      this.setState({
        ...this.state,
        isSameAbove: !isSameAbove,
      });
    }

    if (
      contactPersons[contactPersons.length - 1].ContactPersonIdentifier ===
      selectedContactPerson.ContactPersonIdentifier
    ) {
      this.setState({
        isLastContactPerson: true,
      });
    } else {
      this.setState({
        isLastContactPerson: false,
      });
    }
  };

  handleContactPersonKeyDown = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Tab') {
      const parentSpan = document.querySelector('.k-animation-container');
      if (parentSpan) {
        const childContainer = parentSpan.querySelector(
          '.k-child-animation-container',
        );

        if (childContainer) {
          const footerElement = document.getElementById(
            'tv-dropdown__footer-conactperson__id',
          );
          if (footerElement) {
            footerElement.focus();
            this.setState({
              isContactPersonFooterFocused: true,
            });
            document.removeEventListener(
              'keydown',
              this.handleContactPersonKeyDown,
            );
          }
        }
      } else {
        this.setState({
          isContactPersonFooterFocused: false,
        });
        document.removeEventListener(
          'keydown',
          this.handleContactPersonKeyDown,
        );
      }
    } else if (e.key === 'ArrowUp') {
      this.setState({
        isContactPersonFooterFocused: false,
      });
      document.removeEventListener('keydown', this.handleContactPersonKeyDown);
    }
  };

  handleContactPersonKeyDownEnd = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isContactPersonFooterFocused: false,
      isContactPersonDropdownExpanded: false,
    });
    document.removeEventListener('keydown', this.handleContactPersonKeyDownEnd);
  };

  handleOrderContactPersonKeyDown = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Tab') {
      const parentSpan = document.querySelector('.k-animation-container');
      if (parentSpan) {
        const childContainer = parentSpan.querySelector(
          '.k-child-animation-container',
        );

        if (childContainer) {
          const footerElement = document.getElementById(
            'tv-dropdown__footer-orderconactperson__id',
          );
          if (footerElement) {
            footerElement.focus();
            this.setState({
              isContactOrderPersonFooterFocused: true,
            });
            document.removeEventListener(
              'keydown',
              this.handleOrderContactPersonKeyDown,
            );
          }
        }
      } else {
        this.setState({
          isContactOrderPersonFooterFocused: false,
        });
        document.removeEventListener(
          'keydown',
          this.handleOrderContactPersonKeyDown,
        );
      }
    } else if (e.key === 'ArrowUp') {
      this.setState({
        isContactOrderPersonFooterFocused: false,
      });
      document.removeEventListener(
        'keydown',
        this.handleOrderContactPersonKeyDown,
      );
    }
  };

  handleOrderContactPersonKeyDownEnd = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isContactOrderPersonFooterFocused: false,
      isContactOrderPersonDropdownExpanded: false,
    });
    document.removeEventListener(
      'keydown',
      this.handleOrderContactPersonKeyDownEnd,
    );
  };

  changeContactOrderValue = event => {
    const {
      contactPersons,
      onHandleModal,
      profile,
      handleNewEmailChange,
      CustomerEmail,
    } = this.props;

    const selectedContactPerson = event.target.value;
    const contactPersonData = {
      orderContactPerson: selectedContactPerson
        ? selectedContactPerson.value
        : '',
      orderContactNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberMobile
        : '',
      orderContactDirectNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberDirect
        : '',
      orderEmail: selectedContactPerson ? selectedContactPerson.Email : '',
    };
    if (profile.ContactPersonOrdererRequiresEmail) {
      handleNewEmailChange(selectedContactPerson.Email);
    }
    // else {
    //   handleNewEmailChange(CustomerEmail);
    // }
    this.props.onChange(contactPersonData);

    if (
      contactPersons[contactPersons.length - 1].ContactPersonIdentifier ===
      selectedContactPerson.ContactPersonIdentifier
    ) {
      this.setState({
        isLastContactOrderPerson: true,
      });
    } else {
      this.setState({
        isLastContactOrderPerson: false,
      });
    }
  };

  generateMediaType = () => {
    const { services } = this.props;
    const options = [];
    if (services) {
      services.map(service => {
        if (service.DigitalMeetingPlatforms) {
          service.DigitalMeetingPlatforms.map(item => {
            options.push({
              name: item.DigitalMeetingPlatformName,
              value: item.DigitalMeetingPlatformIdentifier,
            });

            return null;
          });
        }
        return null;
      });
    }

    return options;
  };

  generateFieldsOnAssignmentTypeProps = () => {
    const {
      isEdit,
      selectedAssignmentTypeData,
      meetingLink,
      phoneNumber,
      meetingPassCode,
      meetingPlatform,
    } = this.props;
    const renderedFields = [];

    if (selectedAssignmentTypeData) {
      selectedAssignmentTypeData.map(item => {
        if (item.DoRequireMeetingUrl) {
          const meetingField = (
            <div className="form-group col-12 px-1">
              <label htmlFor="meetingLink">
                <Translate content="singleAssignment.videoLink" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingLink"
                id="meetingLink"
                maxLength={500}
                value={meetingLink}
                placeholder={Translate({
                  content: 'singleAssignment.videoLinkPlaceHolder',
                })}
                validatorListener={this.validateListnerForMeetingUrl}
                validators={['required', 'urlformat']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.videoLink',
                      }),
                    },
                  }),
                  'Videolänk måste börja med "http://" eller "https://"',
                ]}
                aria-label="Videolänk"
              />
            </div>
          );
          const meetingMedia = (
            <div className="form-group col-12 px-1">
              <label htmlFor="meetingPlatform">
                <Translate content="singleAssignment.platform" />
              </label>
              <DropdownList
                onChange={this.handleChange}
                name="meetingPlatform"
                value={meetingPlatform.value === null ? null : meetingPlatform}
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'border-0 invisible-icon' : ''
                }`}
                options={this.generateMediaType()}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.platform',
                      }),
                    },
                  }),
                ]}
                disabled={isEdit ? true : false}
                ariaLabel={Translate({
                  content: 'singleAssignment.platform',
                })}
              />
            </div>
          );

          renderedFields.push(meetingField, meetingMedia);
        }
        if (item.DoRequireMeetingPhoneNumber) {
          const phoneNumberField = (
            <div className="form-group col-12 px-1">
              <label htmlFor="phoneNumber">
                <Translate content="singleAssignment.telephoneNumber" />
              </label>
              <TextField
                onChange={this.handleChange}
                className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'color-for-text' : ''
                }`}
                name="phoneNumber"
                id="phoneNumber"
                style={{ height: '40px' }}
                maxLength={50}
                value={phoneNumber}
                placeholder={Translate({
                  content: 'singleAssignment.telephonePlaceHolder',
                })}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.telephoneNumber',
                      }),
                    },
                  }),
                ]}
                readOnly={isEdit ? true : false}
              />
            </div>
          );
          const meetingPassCodeFiled = (
            <div className="form-group col-12 px-1">
              <label htmlFor="meetingPassCode">
                <Translate content="singleAssignment.passCodeHeader" />
              </label>
              <TextField
                onChange={this.handleChange}
                className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'color-for-text' : ''
                }`}
                name="meetingPassCode"
                id="meetingPassCode"
                style={{ height: '40px' }}
                maxLength={300}
                value={meetingPassCode}
                placeholder={Translate({
                  content: 'singleAssignment.passCodePlaceHolder',
                })}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.passCodeHeader',
                      }),
                    },
                  }),
                ]}
                readOnly={isEdit ? true : false}
              />
            </div>
          );
          renderedFields.push(phoneNumberField, meetingPassCodeFiled);
        }
      });
      return renderedFields;
    }
  };

  isSameDayTime = sess => {
    const { isDefaultDateUpdated } = this.props;
    let returnValue = true;
    let session = cloneDeep(sess);

    const beginningTime = moment({
      h: session.startTime.hours(),
      m: session.startTime.minutes(),
    });
    const endTime = moment({
      h: session.endTime.hours(),
      m: session.endTime.minutes(),
    });
    if (isDefaultDateUpdated) {
      session.ranges.startDate = session.ranges.startDate.clone();
      session.ranges.endDate = session.ranges.endDate.clone();
      if (
        (beginningTime.isAfter(endTime) || endTime.isSame(beginningTime)) &&
        session.ranges.endDate.isSame(session.ranges.startDate, 'day')
      ) {
        returnValue = false;
      }
    }
    return returnValue;
  };

  handleCloseContactPerson = e => {
    const {
      target: { value },
    } = e;
    if (e.nativeEvent.type === 'blur' && this.state.isLastContactPerson) {
      this.setState({
        isContactPersonDropdownExpanded: true,
      });
    } else if (
      e.nativeEvent.type === 'click' ||
      e.nativeEvent.type === 'blur'
    ) {
      this.setState({
        isContactPersonDropdownExpanded: false,
      });
    } else if (e.nativeEvent.key === 'Enter') {
      this.setState({
        isContactPersonDropdownExpanded: false,
      });
    }
    this.props.toggleContactPersonFocus();
  };

  handleCloseOrderContactPerson = e => {
    const {
      target: { value },
    } = e;
    if (e.nativeEvent.type === 'blur' && this.state.isLastContactOrderPerson) {
      this.setState({
        isContactOrderPersonDropdownExpanded: true,
      });
    } else if (
      e.nativeEvent.type === 'click' ||
      e.nativeEvent.type === 'blur'
    ) {
      this.setState({
        isContactOrderPersonDropdownExpanded: false,
      });
    } else if (e.nativeEvent.key === 'Enter') {
      this.setState({
        isContactOrderPersonDropdownExpanded: false,
      });
    }
    this.props.toggleContactOrderPersonFocus();
  };

  handleOpenContactPerson = () => {
    this.setState({
      isContactPersonDropdownExpanded: true,
    });
    this.props.toggleContactPersonFocus();
  };

  handleOpenOrderContactPerson = () => {
    this.setState({
      isContactOrderPersonDropdownExpanded: true,
    });
    this.props.toggleContactOrderPersonFocus();
  };

  toggleLanuageHandler = () => {
    this.props.toggleLanguageFocus();
  };

  handleChangeAdditionalServices = () => {
    const { doIncludeAdditionalServices } = this.props;
    this.props.onChange({
      doIncludeAdditionalServices: !doIncludeAdditionalServices,
    });
  };
  handleChangePatientConsent = () => {
    const { isPatientConsentGivenin } = this.props;
    this.props.onChange({
      isPatientConsentGivenin: !isPatientConsentGivenin,
    });
  };

  handleChangeIsHomeAddress = () => {
    const { isHomeAddress } = this.props;
    this.props.onChange({
      isHomeAddress: !isHomeAddress,
    });
  };

  render() {
    const {
      dateRange,
      language,
      ContactPersonUponInterpretationRequiresEmail,
      contactPerson,
      contactEmail,
      contactDirectNumber,
      contactNumber,
      ContactPersonOrdererRequiresEmail,
      orderContactPerson,
      orderEmail,
      orderContactDirectNumber,
      orderContactNumber,
      languages,
      alternativeLanguages,
      contactPersons,
      services,
      typeOfAssignment,
      assignmentTypeName,
      onClose,
      onSwitchToMultipleOrder,
      openAdvanceView,
      onHandleModal,
      selectedAssignmentTypeData,
      phoneNumber,
      isEdit,
      OrderNumber,
      isAltLanguageAdded,
      addAltLanguage,
      alternativeLanguage,
      preferredTime,
      confirmationEmail,
      lmaNumber,
      messageInfoForInterpreter,
      validatorListener,
      addressLine,
      postalCode,
      city,
      isAlternativeAddressAdded,
      addAlternativeAddress,
      newEmail,
      DoShowLMANumber,
      assignmentDescription,
      message,
      messageReceiver,
      messageTelephone,
      customerOrderEmailAddress,
      CustomerEmail,
      isDeviatingAddressPerforming,
      customerDefaultAddress,
      isDateValid,
      isDefaultDateUpdated,
      isTimeValid,
      messageReceiverAdditionalService,
      sendHoursBeforeBookingOverride,
      doIncludeAdditionalServices,
      isPatientConsentGivenin,
      checkIsPatientConsentGivenin,
      customerName,
      validCustomerInvoice,
      validCustomerInvoices,
    } = this.props;
    const { showLMANumber } = this.state;

    const isMessageServiceAssignment =
      typeOfAssignment.isMessageService === true;
    const assignmentTypes = [];
    if (services) {
      services.map(item => {
        assignmentTypes.push({
          name: item.ServiceName,
          value: item.ServiceIdentifier,
          isMessageService: item.IsMessageService,
          doAllowLMANumberOnConnectedContract:
            item.DoAllowLMANumberOnConnectedContract,
          isPerformedRemotely: item.IsPerformedRemotely,
          additionalServices: item.AdditionalServices,
        });
        return null;
      });
    }

    const filterChange = ({ filter }) => {
      const { value } = filter;
      let filtered;
      if (value) {
        filtered = this.props.languages.filter(l =>
          l.name.toLowerCase().includes(value.toLowerCase()),
        );
      } else {
        filtered = this.props.languages;
      }
      this.setState({
        ...this.state,
        languagesList: filtered,
      });
    };

    const getValuefromList = (filterText, list) => {
      const currentUser = list.filter(x => x.IsCurrentlyLoggedIn === true);
      if (filterText === null || filterText === '') {
        if (currentUser.length > 0) {
          return currentUser[0];
        }
        return null;
      }

      const filteredItems = list.filter(x => x.value === filterText);

      return filteredItems ? filteredItems[0] : null;
    };
    const isContactPersonUponInterpretationValid =
      !contactPerson ||
      contactPerson == '' ||
      isContactPersonValid(
        ContactPersonUponInterpretationRequiresEmail,
        contactPerson,
        contactPersons,
      );

    const isContactPersonOrdererValid =
      !orderContactPerson ||
      orderContactPerson == '' ||
      isContactPersonValid(
        ContactPersonOrdererRequiresEmail,
        orderContactPerson,
        contactPersons,
      );

    const disableDateFrom = currentDate().startOf('day').add(1, 'days');
    const disableDateTo = currentDate().endOf('day').add(1, 'days');
    let isDisableTime = false;
    if (
      dateRange.ranges.startDate >= disableDateFrom &&
      dateRange.ranges.startDate <= disableDateTo
    ) {
      isDisableTime = true;
    }

    const displayAdditionalServices = typeOfAssignment => {
      if (typeOfAssignment.additionalServices) {
        return true;
      }
      return false;
    };

    const tooltipForAdditionalServices = (
      <>
        <i
          className="fa fa-question-circle ml-1 tooltip-icon"
          aria-hidden="true"
        >
          <span className="tooltip-text">
            <p className="mb-2">
              Påminnelsen skickas ut som standard 48 timmar innan bokad tid. Du
              kan själv välja att ändra tiden. Om du avbokar tolken efter att
              SMS-påminnelsen har skickats ut kommer även ett SMS om avbokad tid
              skickas till patient/klient.
            </p>
          </span>
        </i>
      </>
    );

    const tooltipForMessageReceiver = (
      <>
        <i
          className="fa fa-question-circle ml-1 tooltip-icon"
          aria-hidden="true"
        >
          <span className="tooltip-text">
            <p className="mb-2">
              Kontakta oss på 0770–813 000 om du vill ändra avsändarnamn.
            </p>
          </span>
        </i>
      </>
    );

    return (
      <React.Fragment>
        <div className="quick-order-cont side">
          {/* <Scrollbars
            ref={this.scrollContainer}
            className="tv-quick-order__scroll-container"
          > */}
          <div className="form-group col-12 px-1 ">
            <div className="d-flex flex-row mb-2">
              <label htmlFor="typeOfAssignment">
                <Translate content="singleAssignment.typeOfAssignment" />
              </label>
              {!isEdit && (
                <div className="tooltip-icon">
                  <i
                    className="fa fa-question-circle ml-2"
                    aria-hidden="true"
                  ></i>
                  <span className="tooltip-text">
                    <p className="mb-2">
                      Innebär din bokning att tolken förväntas läsa in sig på
                      material eller simultantolka? Då kan din bokning vara ett
                      specialuppdrag. Kontakta oss då på 0770–813 000.​
                    </p>
                    <a
                      className="tooltip-text__link"
                      role="link"
                      tabIndex={0}
                      href="https://www.tolkformedlingvast.se/vara-tjanster/spraktolkning/tjanster.html"
                      target="_blank"
                      aria-label="Läs mer om tjänsterna på vår webbplats."
                    >
                      Läs mer om tjänsterna på vår webbplats.
                    </a>
                  </span>
                </div>
              )}
            </div>

            <DropdownList
              className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                isEdit ? 'border-0 invisible-icon' : ''
              }`}
              name="typeOfAssignment"
              options={assignmentTypes}
              value={getValuefromList(typeOfAssignment.value, assignmentTypes)}
              onChange={this.handleChange}
              validators={['required']}
              errorMessages={[
                Translate({
                  content: 'validator.isRequired',
                  params: {
                    name: Translate({
                      content: 'singleAssignment.typeOfAssignment',
                    }),
                  },
                }),
              ]}
              disabled={isEdit ? true : false}
              ariaLabel={Translate({
                content: 'singleAssignment.typeOfAssignment',
              })}
            />
          </div>

          {validCustomerInvoices.length > 0 && (
            <div className="form-group col-12 px-1 ">
              <div className="d-flex flex-row">
                <label htmlFor="validCustomerInvoice">
                  <Translate content="singleAssignment.validCustomerInvoice" />
                </label>
                <div className="tooltip-icon">
                  <i
                    className="fa fa-question-circle ml-2"
                    aria-hidden="true"
                  ></i>
                  <span className="tooltip-text">
                    Välj den vårdcentralen patienten tillhör.
                  </span>
                </div>
              </div>

              <DropdownList
                onChange={this.handleChange}
                name="validCustomerInvoice"
                required
                value={getValuefromList(
                  validCustomerInvoice.value,
                  validCustomerInvoices,
                )}
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'border-0 invisible-icon' : ''
                }`}
                options={validCustomerInvoices}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.validCustomerInvoice',
                      }),
                    },
                  }),
                ]}
                disabled={isEdit ? true : false}
                ariaLabel={Translate({
                  content: 'singleAssignment.validCustomerInvoice',
                })}
              />
            </div>
          )}

          {typeOfAssignment.isPerformedRemotely == false && (
            <>
              <div className="form-group col-12 px-1 mb-0">
                <label htmlFor="clientAddress">
                  <Translate content="singleAssignment.clientAddress" />
                </label>
                <TextField
                  placeholder={Translate({
                    content: 'singleAssignment.clientAddress',
                  })}
                  maxLength={100}
                  className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                    isEdit ? 'color-for-text border-0' : ''
                  }`}
                  name="clientAddress"
                  id="clientAddress"
                  readOnly={true}
                  value={`${customerDefaultAddress.AddressRow1}, ${customerDefaultAddress.PostalCode}, ${customerDefaultAddress.City} `}
                  aria-label="Kundadress"
                />
              </div>

              {!isAlternativeAddressAdded && !isEdit && (
                <div className="links-add-container">
                  <div
                    className="links-add links-add-secondary links-expand"
                    onClick={() => addAlternativeAddress(true)}
                    onKeyDown={e =>
                      e.key === 'Enter' ? addAlternativeAddress(true) : null
                    }
                    tabIndex={0}
                    role="button"
                    aria-label="Ange avvikande adress"
                  >
                    + Ange avvikande adress
                  </div>
                </div>
              )}

              {isAlternativeAddressAdded && isDeviatingAddressPerforming && (
                <>
                  <div className="form-group col-12 px-1 mt-3">
                    <label htmlFor="addressLine">
                      <Translate content="singleAssignment.enterADifferentAddress" />
                    </label>

                    <TextField
                      validatorListener={validatorListener}
                      placeholder={Translate({
                        content: 'singleAssignment.addressPlaceHolder',
                      })}
                      className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                        isEdit ? 'color-for-text border-0' : ''
                      }`}
                      name="addressLine"
                      id="addressLine"
                      maxLength={300}
                      onChange={this.handleChange}
                      value={addressLine}
                      readOnly={isEdit ? true : false}
                      validators={['required']}
                      errorMessages={[
                        Translate({
                          content: 'validator.isRequired',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.addressLine',
                            }),
                          },
                        }),
                      ]}
                      aria-label="Avvikande utförandeadress"
                    />
                  </div>

                  <div className="form-row col-12 px-1 justify-content-between mx-0 w-100">
                    <div className="form-group col-12 col-md-5 px-0 mb-0">
                      <label htmlFor="postalCode">
                        <Translate content="singleAssignment.postalNumber" />
                      </label>
                      <TextField
                        validatorListener={validatorListener}
                        type="number"
                        placeholder={Translate({
                          content: 'singleAssignment.postalCodePlaceHolder',
                        })}
                        className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                          isEdit ? 'color-for-text border-0' : ''
                        }`}
                        name="postalCode"
                        id="postalCode"
                        maxLength={300}
                        onChange={this.handleChange}
                        value={postalCode.toString()}
                        readOnly={isEdit ? true : false}
                        validators={[
                          'required',
                          'isNumber',
                          'checkNumLength: 5',
                        ]}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.postalCode',
                              }),
                            },
                          }),
                          Translate({
                            content: 'validator.isNotValid',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.postalCode',
                              }),
                            },
                          }),
                          Translate({
                            content: 'validator.requireLength',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.postalCode',
                              }),
                              number: 5,
                            },
                          }),
                        ]}
                        aria-label="Postnummer"
                      />
                    </div>
                    <div className="form-group col-12 col-md-5 px-0 mb-0">
                      <label htmlFor="city">
                        <Translate content="singleAssignment.cityNew" />
                      </label>
                      <TextField
                        validatorListener={validatorListener}
                        placeholder={Translate({
                          content: 'singleAssignment.cityPlaceHolder',
                        })}
                        className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                          isEdit ? 'color-for-text border-0' : ''
                        }`}
                        maxLength={300}
                        name="city"
                        id="city"
                        onChange={this.handleChange}
                        value={city}
                        readOnly={isEdit ? true : false}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.cityNew',
                              }),
                            },
                          }),
                        ]}
                        aria-label="Stad"
                      />
                    </div>
                  </div>
                  <Checkbox
                    label="Utförandeadress avser hemadress"
                    className="label--checked"
                    defaultChecked={this.props.isHomeAddress}
                    value={this.props.isHomeAddress}
                    handleChange={this.handleChangeIsHomeAddress}
                    disabled={isEdit ? true : false}
                  />
                </>
              )}

              {isAlternativeAddressAdded && !isEdit && (
                <div className="links-add-container">
                  <div
                    className="links-add links-add-secondary links-collapsed"
                    onClick={() => addAlternativeAddress(false)}
                    onKeyDown={e =>
                      e.key === 'Enter' ? addAlternativeAddress(false) : null
                    }
                    tabIndex={0}
                    role="button"
                    aria-label="Ta bort avvikande adress"
                  >
                    - Ta bort avvikande adress
                  </div>
                </div>
              )}
            </>
          )}

          <div className="form-group col-12  px-1">
            <div className="d-flex flex-row mb-2">
              <div className="d-flex flex-row">
                <label>
                  <Translate content="singleAssignment.selectDatePeriod" />
                </label>
                {isEdit && (
                  <div className="tooltip-icon">
                    <i
                      className="fa fa-question-circle ml-2"
                      aria-hidden="true"
                    ></i>
                    <span className="tooltip-text">
                      Önskar du ändra datum ber vi dig att avboka och göra en ny
                      bokning eller kontakta oss på 0770–813 000.
                      <br /> <br />
                      Avbokning inom 24 timmar medför debitering.
                    </span>
                  </div>
                )}
              </div>

              {!isEdit && (
                <div className="tooltip-icon">
                  <i
                    className="fa fa-question-circle ml-2"
                    aria-hidden="true"
                  ></i>
                  <span className="tooltip-text">
                    Använd tjänsten Akut tolk vid bokning av tolk innevarande
                    dag samt nästkommande dag fram till kl. 08.00.
                  </span>
                </div>
              )}
            </div>
            <div
              className={`tv-single-assign-form__input ${
                isEdit ? 'invisible-date-border' : ''
              } ${isDateValid ? '' : 'date-not-valid'}`}
            >
              <TFVDateRangePicker
                ranges={dateRange.ranges}
                onChange={this.handleSessionDateChange}
                displayFormat="YYYY-MM-DD"
                disabled={isEdit ? true : false}
                ariaLabel="Datum"
                isDateUpdated={isDefaultDateUpdated}
              />
            </div>
            {!isDateValid && (
              <div className="tv-form__error">Datum är obligatoriskt</div>
            )}
          </div>

          <div className="form-row col-12 px-1 justify-content-between mx-0 w-100">
            {!isMessageServiceAssignment && (
              <>
                <div
                  className={`form-group col-12 col-md-5  px-0 ${
                    isEdit ? 'invisible-border' : ''
                  }`}
                >
                  <div className="d-flex flex-row">
                    <label htmlFor="Start">
                      <Translate content="singleAssignment.startTime" />
                    </label>
                    {isEdit && (
                      <div className="tooltip-icon">
                        <i
                          className="fa fa-question-circle ml-2"
                          aria-hidden="true"
                        ></i>
                        <span className="tooltip-text">
                          Önskar du ändra tid ber vi dig att avboka och göra en
                          ny bokning eller kontakta oss på 0770–813 000. <br />
                          <br />
                          Avbokning inom 24 timmar medför debitering.
                        </span>
                      </div>
                    )}
                  </div>
                  <TFVTimePickerV2
                    defaultValue={dateRange.startTime}
                    startFromDayBegin
                    // allowNextDay
                    handleValueChange={e =>
                      this.handleSessionChange(e, 'startTime')
                    }
                    timeLimit={30}
                    isDisabled={isEdit ? true : false}
                    isDisableTime={isDisableTime}
                    ariaLabel="Start"
                    isTimeValid={isTimeValid}
                    id="Start"
                    name="Start"
                  />
                  {!isTimeValid && (
                    <div className="tv-form__error">Starttiden är ogiltig</div>
                  )}
                </div>

                <div
                  className={`form-group col-12 col-md-5  px-0 ${
                    isEdit ? 'invisible-border' : ''
                  }`}
                >
                  <label htmlFor="Stopp">
                    <Translate content="singleAssignment.endTime" />
                  </label>
                  <TFVTimePickerV2
                    defaultValue={dateRange.endTime}
                    durationCalculate={dateRange.startTime}
                    allowNextDay
                    isNotRanged={false}
                    handleValueChange={e =>
                      this.handleSessionChange(e, 'endTime')
                    }
                    timeLimit={30}
                    isDisabled={isEdit ? true : false}
                    isDisableTime={isDisableTime}
                    ariaLabel="Stopp"
                    id="Stopp"
                    name="Stopp"
                  />
                  {!this.isSameDayTime(dateRange) && (
                    <span className="tv-form__warning">
                      <i className="fa fa-exclamation-circle mr-1"></i>Nästa dag
                    </span>
                  )}
                </div>
              </>
            )}
          </div>

          {isMessageServiceAssignment && (
            <>
              <div
                className={`form-group col-12 col-md-5 px-0 ${
                  isEdit ? 'invisible-border' : ''
                }`}
              >
                <div className="d-flex flex-row mb-2">
                  <label htmlFor="startTime">
                    <Translate content="singleAssignment.preferredTime" />
                  </label>
                  {!isEdit && (
                    <div className="tooltip-icon">
                      <i
                        className="fa fa-question-circle ml-2"
                        aria-hidden="true"
                      ></i>
                      <span className="tooltip-text">
                        Vi kan inte lova leverans enligt detta men kommer att
                        göra vårt bästa för att leverera enligt önskemål
                      </span>
                    </div>
                  )}
                </div>
                <TFVTimePickerV2
                  defaultValue={dateRange.startTime}
                  startFromDayBegin
                  allowNextDay
                  handleValueChange={e =>
                    this.handleSessionChange(e, 'startTime')
                  }
                  timeLimit={15}
                  isDisabled={isEdit ? true : false}
                  isDisableTime={isDisableTime}
                  ariaLabel="Önskad tidpunkt"
                  id="startTime"
                  name="startTime"
                />
              </div>
            </>
          )}

          <div className="form-group col-12 px-1 ">
            <label htmlFor="language">
              <Translate content="singleAssignment.language" />
            </label>

            <DropdownList
              onClose={this.toggleLanuageHandler}
              onOpen={this.toggleLanuageHandler}
              onChange={this.handleChange}
              name="language"
              required
              value={getValuefromList(language.value, languages)}
              className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                isEdit ? 'border-0 invisible-icon' : ''
              }`}
              options={this.state.languagesList}
              validators={['required']}
              errorMessages={[
                Translate({
                  content: 'validator.isRequired',
                  params: {
                    name: Translate({
                      content: 'singleAssignment.language',
                    }),
                  },
                }),
              ]}
              disabled={isEdit ? true : false}
              filterable={true}
              filterChange={filterChange}
              ariaLabel={Translate({ content: 'singleAssignment.language' })}
            />
          </div>
          {!isAltLanguageAdded && alternativeLanguages.length > 0 && (
            <div className="links-add-container">
              <div
                className="links-add links-add-secondary"
                onClick={() => addAltLanguage(true)}
                onKeyDown={e =>
                  e.key === 'Enter' ? addAltLanguage(true) : null
                }
                tabIndex={0}
                role="button"
                aria-label="Lägg till alternativt språk"
              >
                + Lägg till alternativt språk
              </div>
            </div>
          )}
          {isAltLanguageAdded && alternativeLanguages.length > 0 && (
            <>
              <div className="form-group col-12 px-1 ">
                <label htmlFor="alternativeLanguage">Alternativt språk</label>

                <DropdownList
                  onChange={this.handleChange}
                  name="alternativeLanguage"
                  required
                  value={getValuefromList(alternativeLanguage.value, languages)}
                  className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                    isEdit ? 'border-0 invisible-icon' : ''
                  }`}
                  options={alternativeLanguages}
                  validators={['required']}
                  errorMessages={['Alternativt språk är obligatoriskt']}
                  disabled={isEdit ? true : false}
                  ariaLabel="Alternativt språk"
                />
              </div>
              <div className="links-add-container">
                <div
                  className="links-add links-add-secondary"
                  onClick={() => addAltLanguage(false)}
                  onKeyDown={e =>
                    e.key === 'Enter' ? addAltLanguage(false) : null
                  }
                  tabIndex={0}
                  role="button"
                  aria-label="Ta bort alternativt språk"
                >
                  - Ta bort alternativt språk
                </div>
              </div>
            </>
          )}

          <div className="form-group col-12 mb-3 px-1">
            {displayAdditionalServices(typeOfAssignment) && (
              <>
                <Checkbox
                  label="SMS-påminnelse om bokad tid till patient/klient på valt språk"
                  className="label--checked additional-services-condition"
                  defaultChecked={doIncludeAdditionalServices}
                  value="doIncludeAdditionalServices"
                  handleChange={this.handleChangeAdditionalServices}
                  disabled={isEdit ? true : false}
                  tooltip={tooltipForAdditionalServices}
                />
              </>
            )}
            {doIncludeAdditionalServices && (
              <>
                <label htmlFor="messageReceiverAdditionalService">
                  <Translate content="singleAssignment.messageReceiverAdditionalService" />
                </label>
                <TextField
                  placeholder={Translate({
                    content:
                      'singleAssignment.messageReceiverAdditionalServicePlaceholder',
                  })}
                  maxLength={100}
                  className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                  aria-label="Telefonnummer (SMS) till patient/klient"
                  name="messageReceiverAdditionalService"
                  id="messageReceiverAdditionalService"
                  onChange={this.handleChange}
                  value={messageReceiverAdditionalService}
                  validatorListener={validatorListener}
                  validators={['required', 'phoneNumberFormat']}
                  errorMessages={[
                    Translate({
                      content: 'validator.isRequired',
                      params: {
                        name: Translate({
                          content:
                            'singleAssignment.messageReceiverAdditionalService',
                        }),
                      },
                    }),
                    'Angivet telefonnummer har fel format.',
                  ]}
                />
                <label
                  htmlFor="messageReceiverName"
                  className="message-receiver-name"
                >
                  <b>
                    {customerName} {` `}
                  </b>
                  {`kommer att stå som avsändare i skickat SMS. `}{' '}
                  {tooltipForMessageReceiver}
                </label>
                <Checkbox
                  label="Angivet telefonnummer får användas för att skicka SMS enligt inhämtat samtycke från patient/klient. Observera att när bokningen sparas avidentifieras telefonnumret, inga läsbara patientuppgifter finns därmed kopplade till bokningen."
                  className={`label--checked ${
                    checkIsPatientConsentGivenin ? '' : 'patient-consent-given'
                  } additional-services-condition`}
                  defaultChecked={isPatientConsentGivenin}
                  value="isPatientConsentGivenin"
                  handleChange={this.handleChangePatientConsent}
                  disabled={isEdit ? true : false}
                />
                {checkIsPatientConsentGivenin === false && (
                  <span className="tv-form__error mt-0 mb-2">
                    Samtycke från patient/klient krävs
                  </span>
                )}
                <label htmlFor="sendHoursBeforeBookingOverride">
                  <Translate content="singleAssignment.sendHoursBeforeBookingOverride" />
                </label>
                <TextField
                  placeholder={Translate({
                    content:
                      'singleAssignment.sendHoursBeforeBookingOverridePlaceholder',
                  })}
                  maxLength={100}
                  className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                  aria-label="Antal timmar innan bokning som påminnelse skall skickas"
                  name="sendHoursBeforeBookingOverride"
                  id="sendHoursBeforeBookingOverride"
                  onChange={this.handleChange}
                  value={sendHoursBeforeBookingOverride}
                />
              </>
            )}
          </div>

          {this.generateFieldsOnAssignmentTypeProps()}
          {isMessageServiceAssignment && (
            <>
              <div className="form-group col-12 px-1">
                <div className="d-flex flex-row">
                  <label htmlFor="message">
                    <Translate content="singleAssignment.message" />
                  </label>
                  <div className="tooltip-icon">
                    <i
                      className="fa fa-question-circle ml-2"
                      aria-hidden="true"
                    ></i>
                    <span className="tooltip-text">
                      Meddelandeservice är envägskommunikation, dvs. att det
                      inte innehåller frågor för mottagaren att besvara eller
                      uppmuntra till vidare dialog. Meddelandet ska inte
                      innehålla instruktioner om medicin
                    </span>
                  </div>
                </div>
                <TextField
                  onChange={this.handleChange}
                  className={`tv-single-assign-form__textfield tv-single-assign-form__textarea tv-single-assign-form__control ${
                    isEdit ? 'color-for-text' : ''
                  }`}
                  multiline
                  name="message"
                  id="message"
                  style={{ height: '40px' }}
                  // maxLength={300}
                  value={message}
                  placeholder={Translate({
                    content: 'singleAssignment.messagePlaceholder',
                  })}
                  validators={['required', 'messageFieldValid']}
                  errorMessages={[
                    Translate({
                      content: 'validator.isRequired',
                      params: {
                        name: Translate({
                          content: 'singleAssignment.message',
                        }),
                      },
                    }),
                    this.state.errorMessage,
                  ]}
                  readOnly={isEdit ? true : false}
                  aria-label="Meddelande"
                />
              </div>
              <div className="form-group col-12 px-1">
                <label htmlFor="messageReceiver">
                  <Translate content="singleAssignment.recipientName" />
                </label>
                <TextField
                  onChange={this.handleChange}
                  className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                    isEdit ? 'color-for-text border-0' : ''
                  }`}
                  name="messageReceiver"
                  id="messageReceiver"
                  style={{ height: '40px' }}
                  maxLength={300}
                  value={messageReceiver}
                  placeholder={Translate({
                    content: 'singleAssignment.recipientNamePlaceholder',
                  })}
                  validators={['required']}
                  errorMessages={[
                    Translate({
                      content: 'validator.isRequired',
                      params: {
                        name: Translate({
                          content: 'singleAssignment.recipientName',
                        }),
                      },
                    }),
                  ]}
                  readOnly={isEdit ? true : false}
                  aria-label="Mottagarens namn"
                />
              </div>
              <div className="form-group col-12 px-1">
                <label htmlFor="messageTelephone">
                  <Translate content="singleAssignment.recipientPhoneNumber" />
                </label>
                <TextField
                  onChange={this.handleChange}
                  className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                    isEdit ? 'color-for-text border-0' : ''
                  }`}
                  name="messageTelephone"
                  id="messageTelephone"
                  style={{ height: '40px' }}
                  maxLength={300}
                  value={messageTelephone}
                  placeholder={Translate({
                    content: 'singleAssignment.recipientPhoneNumberPlaceholder',
                  })}
                  validators={['required']}
                  errorMessages={[
                    Translate({
                      content: 'validator.isRequired',
                      params: {
                        name: Translate({
                          content: 'singleAssignment.recipientPhoneNumber',
                        }),
                      },
                    }),
                  ]}
                  readOnly={isEdit ? true : false}
                  aria-label="Mottagarens telefonnummer"
                />
              </div>
            </>
          )}
          <>
            <div
              className={`form-group col-12 px-1 ${
                isContactPersonUponInterpretationValid ? '' : 'mb-5'
              }`}
            >
              <div className="d-flex flex-row">
                <label htmlFor="contactPerson">
                  <Translate content="singleAssignment.contactPerson" />
                </label>
                <div className="tooltip-icon">
                  <i
                    className="fa fa-question-circle ml-2"
                    aria-hidden="true"
                  ></i>
                  <span className="tooltip-text">
                    Den som har samtalet med patient/klient.
                  </span>
                </div>
              </div>
              <DropdownList
                opened={this.state.isContactPersonDropdownExpanded}
                onClose={this.handleCloseContactPerson}
                onOpen={this.handleOpenContactPerson}
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control tv-single-assign-form__control-contact-person ${
                  isContactPersonUponInterpretationValid
                    ? ''
                    : 'tv-form__control--error'
                } ${isEdit ? 'border-0 invisible-icon' : ''}`}
                name="contactPerson"
                options={contactPersons}
                value={getValuefromList(contactPerson, contactPersons)}
                required
                onChange={e => {
                  this.changeContactValue(e);
                }}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.contactPerson',
                      }),
                    },
                  }),
                ]}
                footer={
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonUponInterpretationRequiresEmail,
                        'ContactPersonUponInterpretation',
                      );
                      this.setState({
                        isContactPersonDropdownExpanded: false,
                      });
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        onHandleModal(
                          ContactPersonUponInterpretationRequiresEmail,
                          'ContactPersonUponInterpretation',
                        );
                        this.setState({
                          isContactPersonDropdownExpanded: false,
                        });
                      }
                    }}
                    className="tv-dropdown__footer-conactperson-add tv-dropdown__footer-conactperson-add-contactPerson"
                    tabIndex={0}
                    role="button"
                    aria-label="Lägg till tolkanvändare"
                    id="tv-dropdown__footer-conactperson__id"
                  >
                    + Lägg till tolkanvändare
                  </span>
                }
                disabled={isEdit ? true : false}
                ariaLabel={Translate({
                  content: 'singleAssignment.contactPerson',
                })}
              />
              {!isContactPersonUponInterpretationValid && (
                <span className="tv-form__error text-justify">
                  E-post krävs.
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonUponInterpretationRequiresEmail,
                        'ContactPersonUponInterpretation',
                        contactPerson,
                      );
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                        onHandleModal(
                          ContactPersonUponInterpretationRequiresEmail,
                          'ContactPersonUponInterpretation',
                          contactPerson,
                        );
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label="Lägg till här"
                    className="contact-person-email-edit"
                  >
                    + Lägg till här
                  </span>
                </span>
              )}
            </div>
            <div className="form-group col-12 px-1">
              <label htmlFor="orderContactPerson">
                <Translate content="singleAssignment.contactOrderPerson" />
              </label>
              <DropdownList
                opened={this.state.isContactOrderPersonDropdownExpanded}
                onClose={this.handleCloseOrderContactPerson}
                onOpen={this.handleOpenOrderContactPerson}
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control tv-single-assign-form__control-order-contact-person ${
                  isContactPersonOrdererValid ? '' : 'tv-form__control--error'
                } ${isEdit ? 'border-0 invisible-icon' : ''}`}
                name="orderContactPerson"
                options={contactPersons}
                footer={
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonOrdererRequiresEmail,
                        'ContactPersonOrderer',
                      );
                      this.setState({
                        isContactOrderPersonDropdownExpanded: false,
                      });
                    }}
                    className="tv-dropdown__footer-conactperson-add tv-dropdown__footer-conactperson-add-ordercontactPerson"
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        onHandleModal(
                          ContactPersonOrdererRequiresEmail,
                          'ContactPersonOrderer',
                        );
                        this.setState({
                          isContactOrderPersonDropdownExpanded: false,
                        });
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label="Lägg till beställare"
                    id="tv-dropdown__footer-orderconactperson__id"
                  >
                    + Lägg till beställare
                  </span>
                }
                value={getValuefromList(orderContactPerson, contactPersons)}
                required
                onChange={(e, v) => {
                  this.changeContactOrderValue(e);
                }}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.orderContactPerson',
                      }),
                    },
                  }),
                ]}
                disabled={isEdit ? true : false}
                ariaLabel={Translate({
                  content: 'singleAssignment.contactOrderPerson',
                })}
              />
              {!isContactPersonOrdererValid && (
                <span className="tv-form__error text-justify">
                  E-post krävs.
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonOrdererRequiresEmail,
                        'ContactPersonOrderer',
                        orderContactPerson,
                      );
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                        onHandleModal(
                          ContactPersonOrdererRequiresEmail,
                          'ContactPersonOrderer',
                          orderContactPerson,
                        );
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label="Lägg till här"
                    className="contact-person-email-edit"
                  >
                    + Lägg till här
                  </span>
                </span>
              )}
            </div>
            <div className="form-group col-12 col-lg-12 px-1">
              <label htmlFor="newEmail">
                <Translate content="singleAssignment.email" />
              </label>
              <TextField
                placeholder={Translate({
                  content: 'singleAssignment.email',
                })}
                className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control color-for-text ${
                  isEdit ? 'border-0' : ''
                }`}
                readOnly={true}
                value={newEmail}
                id="newEmail"
                name="newEmail"
                aria-label={Translate({ content: 'singleAssignment.email' })}
              />
            </div>
            <div className="form-group col-12 col-lg-12 px-1">
              <div className="d-flex flex-row">
                <label htmlFor="customerOrderEmailAddress">
                  <Translate content="singleAssignment.confirmationEmail" />
                </label>
                <div className="tooltip-icon">
                  <i
                    className="fa fa-question-circle ml-2"
                    aria-hidden="true"
                  ></i>
                  <span className="tooltip-text">
                    Om du vill att bekräftelsen ska skickas till flera
                    e-postadresser, ange dessa med semikolon emellan.
                  </span>
                </div>
              </div>
              <TagInput
                placeholder={Translate({
                  content:
                    'singleAssignment.customerOrderEmailAddressPlaceholder',
                })}
                value={this.state.tagInputText}
                name="customerOrderEmailAddress"
                id="customerOrderEmailAddress"
                confirmationEmailList={customerOrderEmailAddress}
                onChange={this.handleEmailChange}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.handleKeyUp}
                deleteTag={this.handleDeleteTagInput}
                aria-label={Translate({
                  content:
                    'singleAssignment.customerOrderEmailAddressPlaceholder',
                })}
                onBlur={this.handleCheckEmail}
                validators={['validEmail']}
                errorMessages={['Ogiltig e-postadress']}
              />
            </div>

            {/* {isMessageServiceAssignment && (
              <div className="col-12 form-group px-1">
                <label>
                  <Translate content="taskDetail.infoForInterpreter" />
                </label>
                <TextField
                  placeholder={Translate({
                    content: 'singleAssignment.typeNotesForResourceNew',
                  })}
                  multiline
                  className="tv-single-assign-form__textfield tv-single-assign-form__textarea tv-single-assign-form__control"
                  onChange={this.handleChange}
                  name="assignmentDescription"
                  value={assignmentDescription}
                />
              </div>
            )} */}
          </>

          <div className="form-group col-12 px-1">
            <label htmlFor="assignmentDescription">
              <Translate content="taskDetail.infoForInterpreter" />
              <HelpContext
                theme="light"
                style={{}}
                content={Translate({
                  content: 'helpContent.infoForInterpreterHelp',
                })}
              />
            </label>

            <TextField
              aria-label="Information till tolk"
              validatorListener={validatorListener}
              placeholder={Translate({
                content: 'singleAssignment.typeNotesForResourceNew',
              })}
              onChange={this.handleChange}
              multiline
              className="tv-single-assign-form__textfield tv-single-assign-form__control mb-0"
              name="assignmentDescription"
              id="assignmentDescription"
              value={assignmentDescription}
            />
          </div>

          {showLMANumber && (
            <div className="form-group col-12 px-1">
              <label htmlFor="lmaNumber">
                <Translate content="singleAssignment.LMANumber" />
              </label>
              <TextField
                placeholder={Translate({
                  content: 'singleAssignment.lmaNumberPlaceholder',
                })}
                maxLength={100}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                aria-label="LMA Number"
                name="lmaNumber"
                id="lmaNumber"
                onChange={this.handleChange}
                value={lmaNumber}
              />
            </div>
          )}

          {/* </Scrollbars> */}
          <div className="d-flex flex-column tk-assignment-creation__footer">
            {!isMessageServiceAssignment && (
              <div className="ml-2 mt-2">
                <div
                  className="links-add"
                  onClick={openAdvanceView}
                  tabIndex={0}
                  onKeyPress={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      openAdvanceView();
                    }
                  }}
                  aria-label={
                    !isEdit
                      ? 'Lägg till krav och ytterligare information'
                      : 'Ändra krav'
                  }
                  role="button"
                >
                  {!isEdit
                    ? '+ Lägg till krav och ytterligare information'
                    : // : `Ändra krav ${OrderNumber || ''}`}
                      `Ändra krav`}
                </div>
              </div>
            )}
            <div className="tv-workassignment__detail-buttons justify-content-between tv-display-flex">
              <TFVButton
                type="secondary"
                buttonType="submit"
                className="w-50  ml-1"
                text={Translate({
                  content: 'general.continue',
                })}
              />
              <TFVButton
                type="line-primary"
                buttonType="button"
                className="w-50"
                text={Translate({
                  content: 'general.close',
                })}
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

QuickOrderForm.propTypes = propTypes;
QuickOrderForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  DoShowLMANumber: state.user.profile.DoShowLMANumber,
  CustomerEmail: state.user.profile.CustomerEmail,
  customerName: state.user.profile.customerName,
});

export default connect(mapStateToProps)(QuickOrderForm);
